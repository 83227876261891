import React, { useState, useRef, useEffect } from "react";
import "./App.css";
import "react-toastify/dist/ReactToastify.css";
import { ThemeProvider, createTheme } from '@mui/material/styles';
import Sound from 'react-sound';
import Image from 'react-image-resizer';

import MyRoutes from "./routes";
import { Desktop } from './responsive'

import Box from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Snackbar from '@mui/material/Snackbar';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';


import Mariage_dAmour from './assets/Mariage-dAmour.mp3';


window.soundManager.setup({

  debugMode: false,

  defaultOptions: {
    //volume: 30
  }

});



function App() {
  const [playingTime, setPlayingTime] = useState(0);
  const [snackbarOpen, setSnackbarOpen] = React.useState(false);
  const [renderStatus, setRenderStatus] = useState(Sound.status.PLAYING);

  let timesCheckedPlayingTm = useRef(0);

  useEffect(() => {
    setPlayingTime(JSON.parse(window.localStorage.getItem('playingTime')));

    setTimeout(() => {
      if (timesCheckedPlayingTm.current < 3) {
        setSnackbarOpen(true)
      }
    }, 2000)
  }, []);

  useEffect(() => {
    window.localStorage.setItem('playingTime', playingTime);
    timesCheckedPlayingTm.current += 1;
  }, [playingTime]);


  function playAudio() {
    setSnackbarOpen(false);
    setRenderStatus(Sound.status.PAUSE);
    setTimeout(() => {setRenderStatus(Sound.status.PLAYING)}, 10);
  }


  function handleSnackbarClose(event, reason) {
    if (reason) {
      if (reason === 'clickaway') {
        return;
      };
    };
    
    setSnackbarOpen(false);
  };


  const theme = createTheme({
    typography: {
      allVariants: {
        fontFamily: 'Quicksand',
        textTransform: 'none',
        fontSize: 16,
      },
    },
  });

  
  return (
    <div className="App">

      <Desktop>
        { window.location.href.split("/")[window.location.href.split("/").length-1].split("-").length === 1 ?
        <Sound
          url={Mariage_dAmour}
          playStatus={renderStatus}
          playFromPosition={playingTime}
          volume={30}
          onPlaying={(event) => {setPlayingTime(event["position"]);}}
          onFinishedPlaying={() => {setPlayingTime(0)}}
        />
        : null }
      </Desktop>

      <ThemeProvider theme={theme}>
        <MyRoutes playFunction={playAudio} />
      </ThemeProvider>

      <Desktop>
        { window.location.href.split("/")[window.location.href.split("/").length-1] !== "" && window.location.href.split("/")[window.location.href.split("/").length-1].split("-").length === 1 ?
        <Snackbar
          open={snackbarOpen}
          anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
          autoHideDuration={10000}
          onClose={handleSnackbarClose}
          message={
            <Box onClick={playAudio} sx={{ height: "100px", width: "200px", pb: "100px"}}>
              <Image src={require("./assets/violinist.png")} height="100" width="100" />
              <Typography sx={{ color: "#ffffff", mt: "95px", ml: "100px", pr: "-100px" }}>Let me play</Typography>
            </Box>
            }

          action={
            <IconButton
              size="small"
              aria-label="close"
              color="inherit"
              onClick={handleSnackbarClose}
            >
              <CloseIcon fontSize="small" />
            </IconButton>
            }
        /> 
        : null}
      </Desktop>

    </div>
  );
}

export default App;
