import React from "react";
import { Desktop, Tablet, Mobile } from '../../responsive'

import Header from "../Header/Header";
import Footer from "../Footer/Footer";

import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import OpenInNewRoundedIcon from '@mui/icons-material/OpenInNewRounded';

import Image from 'react-image-resizer';



export default function Davos() {
  
    return (
      <div>
      <Header />

      <Desktop>
        <Box sx={{ px: "12%", mt: "10px", mb: "0px" }}>

          <div>
          <Box sx={{ width: "360px", height: "100px" }}>
            <Typography sx={{ color: "#222", fontSize: "36px", fontWeight: "500" }}>
              DAVOS
            </Typography>
          </Box>

          <Stack direction="row" spacing={4} >

            <Box sx={{ width: "590px", height: "300px" }}>
              <Typography sx={{ color: "#222", fontSize: "16px", mt: "12px", lineHeight: 1.7 }}>
                <i>Davos</i> is the best generative algorithm we have developed so far. Written in Python, it combines multiple layers from different colors to create awesome results. By using lots of shapes, colors and composition styles, it is capable of generating endless outputs, all of them totally different from each other. What makes <i>Davos</i> so different from other generative algorithms is that the shapes used in the compositions do not follow some pattern, and certanly can't be categorized as linear shapes. Their organic format is due to the projection of three dimensional functions in the two dimensional plane. After combining hundreds or thousands of those projections, the resulting output is an outstandingly beautiful image, a vivid mixture of colors and formats. <br /><a href="/" target="_blank" rel="noopener noreferrer">Check out a live and interactive web simulation of Davos <OpenInNewRoundedIcon sx={{ fontSize: "17px", mt: "-2px" }} /></a>
              </Typography>
              <Typography sx={{ color: "#222", fontSize: "16px", mt: "12px", lineHeight: 1.7 }}>
                Now let's dive into the special features and the variety of output that <i>Davos</i> has to offer.
              </Typography>
            </Box>

            <Box sx={{ width: "360px", height: "380px", pl: "30px" }}>
              <Image src={require("../../assets/Davos/initial.png")} height="360" width="360" />
            </Box>

          </Stack>
          </div>

          <div>
          <Typography sx={{ mt: "72px", color: "#222", fontSize: "24px", fontWeight: "500" }}>
            LAYER BY LAYER
          </Typography>

          <Box sx={{ width: "1000px", mt: "16px", mb: "40px" }}>
            <Typography sx={{ color: "#222", fontSize: "16px", lineHeight: 1.7}}>
              Every <i>Davos</i> output is composed by multiple layers. Each layer is a projection of a three dimensional function in a single color, with variable opacity. For the projection we use a combination of two advanced mathematical functions, both choosen randomly from a big set of different functions, using random parameters and random projection methods. This is how single layers looks like:
            </Typography>
          </Box>

          <Box sx={{ width: "1000px", height: "300px" }}>
            <Stack direction="row" justifyContent="space-evenly" sx={{ ml: "-25%" }}>
              <Image src={require("../../assets/Davos/shapes1.png")} height="250" width="250" />
              <Image src={require("../../assets/Davos/shapes2.png")} height="250" width="250" />
              <Image src={require("../../assets/Davos/shapes3.png")} height="250" width="250" />
            </Stack>
          </Box>

          <Box sx={{ width: "1000px",mb: "40px" }}>
            <Typography sx={{ color: "#222", fontSize: "16px", lineHeight: 1.7}}>
              The vast majority of this shapes are rectlinear projections, but there are lots of them using different projection methods as well. One of this methods, that deserves special enphasis, is the polar projection, which generates shapes that resemble circles:
            </Typography>
          </Box>

          <Box sx={{ width: "1000px", height: "300px" }}>
            <Stack direction="row" justifyContent="space-evenly" sx={{ ml: "-25%" }}>
              <Image src={require("../../assets/Davos/circular1.png")} height="280" width="280" />
              <Image src={require("../../assets/Davos/circular2.png")} height="280" width="280" />
            </Stack>
          </Box>

          <Box sx={{ width: "1000px",mb: "40px" }}>
            <Typography sx={{ color: "#222", fontSize: "16px", lineHeight: 1.7}}>
              The algorithm generates a large quantity of this layers, in different colors and formats and then combine them with the colorful background and <i>voilà</i>, we get some awesome output.
            </Typography>
            <Typography sx={{ color: "#222", fontSize: "16px", mt: "12px", lineHeight: 1.7}}>
              Now that we saw the algorithm's generation process, we shall dive into all the variations it can produce.
            </Typography>
          </Box>
          </div>

          <div>
          <Typography sx={{ mt: "72px", color: "#222", fontSize: "24px", fontWeight: "500" }}>
            COLOR PALETTES
          </Typography>

          <Box sx={{ width: "1000px", mt: "16px", mb: "40px" }}>
            <Typography sx={{ color: "#222", fontSize: "16px", lineHeight: 1.7}}>
              <i>Davos</i> was programmed with 37 different color palettes, each of them with only five colors. Only five colors? How is the output so colorful then?
            </Typography>
            <Typography sx={{ color: "#222", fontSize: "16px", mt: "12px", lineHeight: 1.7}}>
              The colors used by the algorithm are not limited by the selected palette. The color palettes are only used to define a basis for the output. For a better understanding, lets take a look at the creation process, from a coloring perspective: 
            </Typography>
            <Typography sx={{ color: "#222", fontSize: "16px", mt: "12px", lineHeight: 1.7}}>
              At first, the algorithm gets a random palette, selects one color from it and uses it as the background color. In the next stage, it generates the shapes to be merged with the background, using one of the four restant colors, choosing each shapes color based on a probabilistic distribution. Every time <i>Davos</i> generates a shape, there is a possibility that it will select a random color for it, instead of using one from the pallete. This phenomenon grants the output some extra vitality, those outsider colors are the ones responsible for its brightness. Here are some examples of its 37 different palettes:
            </Typography>
          </Box>

          <Box sx={{ width: "1000px", height: "2600px" }}>
            <div>
            <Stack direction="row" justifyContent="space-evenly" sx={{ ml: "-20%", mb: "40px" }}>
              <Image src={require("../../assets/Davos/palette_samples/agate.png")} height="200" width="200" />
              <Image src={require("../../assets/Davos/palette_samples/alexandrite.png")} height="200" width="200" />
              <Image src={require("../../assets/Davos/palette_samples/amazonite.png")} height="200" width="200" />
              <Image src={require("../../assets/Davos/palette_samples/amber.png")} height="200" width="200" />
            </Stack>
            <Stack direction="row" justifyContent="space-evenly" sx={{ ml: "4%", mt: "205px", mb: "40px" }}>
              <Box height="100" width="25%"><Typography sx={{fontSize: "14px"}}>Agate</Typography></Box>
              <Box height="100" width="25%"><Typography sx={{fontSize: "14px"}}>Alexandrite</Typography></Box>
              <Box height="100" width="25%"><Typography sx={{fontSize: "14px"}}>Amazonite</Typography></Box>
              <Box height="100" width="25%"><Typography sx={{fontSize: "14px"}}>Amber</Typography></Box>
            </Stack>
            </div>

            <div>
            <Stack direction="row" justifyContent="space-evenly" sx={{ ml: "-20%", mb: "40px" }}>
              <Image src={require("../../assets/Davos/palette_samples/amethyst.png")} height="200" width="200" />
              <Image src={require("../../assets/Davos/palette_samples/ametrine.png")} height="200" width="200" />
              <Image src={require("../../assets/Davos/palette_samples/anyolite.png")} height="200" width="200" />
              <Image src={require("../../assets/Davos/palette_samples/apatite.png")} height="200" width="200" />
            </Stack>
            <Stack direction="row" justifyContent="space-evenly" sx={{ ml: "4%", mt: "205px", mb: "40px" }}>
              <Box height="100" width="25%"><Typography sx={{fontSize: "14px"}}>Amethyst</Typography></Box>
              <Box height="100" width="25%"><Typography sx={{fontSize: "14px"}}>Ametrine</Typography></Box>
              <Box height="100" width="25%"><Typography sx={{fontSize: "14px"}}>Anyolite</Typography></Box>
              <Box height="100" width="25%"><Typography sx={{fontSize: "14px"}}>Apatite</Typography></Box>
            </Stack>
            </div>

            <div>
            <Stack direction="row" justifyContent="space-evenly" sx={{ ml: "-20%", mb: "40px" }}>
              <Image src={require("../../assets/Davos/palette_samples/aventurine.png")} height="200" width="200" />
              <Image src={require("../../assets/Davos/palette_samples/azurite.png")} height="200" width="200" />
              <Image src={require("../../assets/Davos/palette_samples/beryl.png")} height="200" width="200" />
              <Image src={require("../../assets/Davos/palette_samples/chrysoprase.png")} height="200" width="200" />
            </Stack>
            <Stack direction="row" justifyContent="space-evenly" sx={{ ml: "4%", mt: "205px", mb: "40px" }}>
              <Box height="100" width="25%"><Typography sx={{fontSize: "14px"}}>Aventurine</Typography></Box>
              <Box height="100" width="25%"><Typography sx={{fontSize: "14px"}}>Azurite</Typography></Box>
              <Box height="100" width="25%"><Typography sx={{fontSize: "14px"}}>Beryl</Typography></Box>
              <Box height="100" width="25%"><Typography sx={{fontSize: "14px"}}>Chrysoprase</Typography></Box>
            </Stack>
            </div>

            <div>
            <Stack direction="row" justifyContent="space-evenly" sx={{ ml: "-20%", mb: "40px" }}>
              <Image src={require("../../assets/Davos/palette_samples/citrine.png")} height="200" width="200" />
              <Image src={require("../../assets/Davos/palette_samples/emerald.png")} height="200" width="200" />
              <Image src={require("../../assets/Davos/palette_samples/fluorite.png")} height="200" width="200" />
              <Image src={require("../../assets/Davos/palette_samples/goshenite.png")} height="200" width="200" />
            </Stack>
            <Stack direction="row" justifyContent="space-evenly" sx={{ ml: "4%", mt: "205px", mb: "40px" }}>
              <Box height="100" width="25%"><Typography sx={{fontSize: "14px"}}>Citrine</Typography></Box>
              <Box height="100" width="25%"><Typography sx={{fontSize: "14px"}}>Emerald</Typography></Box>
              <Box height="100" width="25%"><Typography sx={{fontSize: "14px"}}>Fluorite</Typography></Box>
              <Box height="100" width="25%"><Typography sx={{fontSize: "14px"}}>Goshenite</Typography></Box>
            </Stack>
            </div>

            <div>
            <Stack direction="row" justifyContent="space-evenly" sx={{ ml: "-20%", mb: "40px" }}>
              <Image src={require("../../assets/Davos/palette_samples/heliodor.png")} height="200" width="200" />
              <Image src={require("../../assets/Davos/palette_samples/hematite.png")} height="200" width="200" />
              <Image src={require("../../assets/Davos/palette_samples/jade.png")} height="200" width="200" />
              <Image src={require("../../assets/Davos/palette_samples/jasper.png")} height="200" width="200" />
            </Stack>
            <Stack direction="row" justifyContent="space-evenly" sx={{ ml: "4%", mt: "205px", mb: "40px" }}>
              <Box height="100" width="25%"><Typography sx={{fontSize: "14px"}}>Heliodor</Typography></Box>
              <Box height="100" width="25%"><Typography sx={{fontSize: "14px"}}>Hematite</Typography></Box>
              <Box height="100" width="25%"><Typography sx={{fontSize: "14px"}}>Jade</Typography></Box>
              <Box height="100" width="25%"><Typography sx={{fontSize: "14px"}}>Jasper</Typography></Box>
            </Stack>
            </div>

            <div>
            <Stack direction="row" justifyContent="space-evenly" sx={{ ml: "-20%", mb: "40px" }}>
              <Image src={require("../../assets/Davos/palette_samples/kunzite.png")} height="200" width="200" />
              <Image src={require("../../assets/Davos/palette_samples/lepidolite.png")} height="200" width="200" />
              <Image src={require("../../assets/Davos/palette_samples/malachite.png")} height="200" width="200" />
              <Image src={require("../../assets/Davos/palette_samples/moonstone.png")} height="200" width="200" />
            </Stack>
            <Stack direction="row" justifyContent="space-evenly" sx={{ ml: "4%", mt: "205px", mb: "40px" }}>
              <Box height="100" width="25%"><Typography sx={{fontSize: "14px"}}>Kunzite</Typography></Box>
              <Box height="100" width="25%"><Typography sx={{fontSize: "14px"}}>Lepidolite</Typography></Box>
              <Box height="100" width="25%"><Typography sx={{fontSize: "14px"}}>Malachite</Typography></Box>
              <Box height="100" width="25%"><Typography sx={{fontSize: "14px"}}>Moonstone</Typography></Box>
            </Stack>
            </div>

            <div>
            <Stack direction="row" justifyContent="space-evenly" sx={{ ml: "-20%", mb: "40px" }}>
              <Image src={require("../../assets/Davos/palette_samples/onyx.png")} height="200" width="200" />
              <Image src={require("../../assets/Davos/palette_samples/opal.png")} height="200" width="200" />
              <Image src={require("../../assets/Davos/palette_samples/pearl.png")} height="200" width="200" />
              <Image src={require("../../assets/Davos/palette_samples/quartz.png")} height="200" width="200" />
            </Stack>
            <Stack direction="row" justifyContent="space-evenly" sx={{ ml: "4%", mt: "205px", mb: "40px" }}>
              <Box height="100" width="25%"><Typography sx={{fontSize: "14px"}}>Onyx</Typography></Box>
              <Box height="100" width="25%"><Typography sx={{fontSize: "14px"}}>Opal</Typography></Box>
              <Box height="100" width="25%"><Typography sx={{fontSize: "14px"}}>Pearl</Typography></Box>
              <Box height="100" width="25%"><Typography sx={{fontSize: "14px"}}>Quartz</Typography></Box>
            </Stack>
            </div>

            <div>
            <Stack direction="row" justifyContent="space-evenly" sx={{ ml: "-20%", mb: "40px" }}>
              <Image src={require("../../assets/Davos/palette_samples/rhodonite.png")} height="200" width="200" />
              <Image src={require("../../assets/Davos/palette_samples/ruby.png")} height="200" width="200" />
              <Image src={require("../../assets/Davos/palette_samples/sapphire.png")} height="200" width="200" />
              <Image src={require("../../assets/Davos/palette_samples/sphalerite.png")} height="200" width="200" />
            </Stack>
            <Stack direction="row" justifyContent="space-evenly" sx={{ ml: "4%", mt: "205px", mb: "40px" }}>
              <Box height="100" width="25%"><Typography sx={{fontSize: "14px"}}>Rhodonite</Typography></Box>
              <Box height="100" width="25%"><Typography sx={{fontSize: "14px"}}>Ruby</Typography></Box>
              <Box height="100" width="25%"><Typography sx={{fontSize: "14px"}}>Sapphire</Typography></Box>
              <Box height="100" width="25%"><Typography sx={{fontSize: "14px"}}>Sphalerite</Typography></Box>
            </Stack>
            </div>

            <div>
            <Stack direction="row" justifyContent="space-evenly" sx={{ ml: "-20%", mb: "40px" }}>
              <Image src={require("../../assets/Davos/palette_samples/sugilite.png")} height="200" width="200" />
              <Image src={require("../../assets/Davos/palette_samples/tanzanite.png")} height="200" width="200" />
              <Image src={require("../../assets/Davos/palette_samples/variscite.png")} height="200" width="200" />
              <Image src={require("../../assets/Davos/palette_samples/wonderstone.png")} height="200" width="200" />
            </Stack>
            <Stack direction="row" justifyContent="space-evenly" sx={{ ml: "4%", mt: "205px", mb: "40px" }}>
              <Box height="100" width="25%"><Typography sx={{fontSize: "14px"}}>Sugilite</Typography></Box>
              <Box height="100" width="25%"><Typography sx={{fontSize: "14px"}}>Tanzanite</Typography></Box>
              <Box height="100" width="25%"><Typography sx={{fontSize: "14px"}}>Variscite</Typography></Box>
              <Box height="100" width="25%"><Typography sx={{fontSize: "14px"}}>Wonderstone</Typography></Box>
            </Stack>
            </div>

            <div>
            <Stack direction="row" justifyContent="space-evenly" sx={{ ml: "-20%" }}>
              <Image src={require("../../assets/Davos/palette_samples/zircon.png")} height="200" width="200" />
              <Box height="200" width="200"></Box>
              <Box height="200" width="200"></Box>
              <Box height="200" width="200"></Box>
            </Stack>
            <Stack direction="row" justifyContent="space-evenly" sx={{ ml: "4%", mt: "205px", mb: "40px" }}>
              <Box height="100" width="25%"><Typography sx={{fontSize: "14px"}}>Zircon</Typography></Box>
              <Box height="100" width="25%"><Typography sx={{fontSize: "14px"}}></Typography></Box>
              <Box height="100" width="25%"><Typography sx={{fontSize: "14px"}}></Typography></Box>
              <Box height="100" width="25%"><Typography sx={{fontSize: "14px"}}></Typography></Box>
            </Stack>
            </div>
          </Box>

          <Box sx={{ width: "1000px", mt: "50px" }}>
            <Typography sx={{ color: "#222", fontSize: "16px", lineHeight: 1.7}}>
              Apart from the color palette selection, there are many other key factors that have a strong influence on the outputs coloring. We will now get further into all this features, one by one.
            </Typography>
          </Box>
          </div>

          <div>
          <Typography sx={{ mt: "72px", color: "#222", fontSize: "24px", fontWeight: "500" }}>
            COLOR DISTRIBUTION
          </Typography>

          <Box sx={{ width: "1000px", mt: "16px", mb: "0px" }}>
            <Typography sx={{ color: "#222", fontSize: "16px", lineHeight: 1.7}}>
              As mentioned before, after the algorithm selects a background color, there are four colors left that will be used to generate most of the shapes. But in fact, before starting to generate the shapes, it defines a probabilistic color distribution; the proportion of each color on the final output. There are five color distributions used by the algorithm: 
            </Typography>

            <ul>
              <li>
                <Typography sx={{ color: "#222", fontSize: "16px", mt: "10px", lineHeight: 1.7}}>
                  <span>•</span> Sonata - One predominant color, others with a small proportion
                </Typography>
              </li>
              <li>
                <Typography sx={{ color: "#222", fontSize: "16px", mt: "10px", lineHeight: 1.7}}>
                  <span>•</span> Concerto - One main color, others with a medium or small proportion
                </Typography>
              </li>
              <li>
                <Typography sx={{ color: "#222", fontSize: "16px", mt: "10px", lineHeight: 1.7}}>
                  <span>•</span> Cantata - Similar color proportions, with small dispartions
                </Typography>
              </li>
              <li>
                <Typography sx={{ color: "#222", fontSize: "16px", mt: "10px", lineHeight: 1.7}}>
                  <span>•</span> Fugue - Two main colors and other two with minor participation
                </Typography>
              </li>
              <li>
                <Typography sx={{ color: "#222", fontSize: "16px", mt: "10px", lineHeight: 1.7}}>
                  <span>•</span> Symphony - All colors in the same proportion
                </Typography>
              </li>
            </ul>

            <Typography sx={{ color: "#222", fontSize: "16px", mt: "30px", lineHeight: 1.7}}>
              The differences between the distribution are subtle, but have a strong impact on the final result. Eventhough the two images bellow use the same color palette and the same background color, as you can see, there are clearly different proportions of colors in each of them:
            </Typography>
          </Box>

          <Box sx={{ width: "1000px", height: "400px", mt: "16px", mb: "20px" }}>
            <Stack direction="row" justifyContent="space-evenly" sx={{ ml: "-36%" }}>
              <Image src={require("../../assets/Davos/distribution1.png")} height="360" width="360" />
              <Image src={require("../../assets/Davos/distribution2.png")} height="360" width="360" />
            </Stack>
          </Box>

          <Box sx={{ width: "1000px", mt: "0px" }}>
            <Typography sx={{ color: "#222", fontSize: "16px", lineHeight: 1.7}}>
              Even using the same palette and the same color distribution there is still great variety in coloration, once the proportions of that distribution can be assigned to different colors.
            </Typography>
          </Box>
          </div>

          <div>
          <Typography sx={{ mt: "72px", color: "#222", fontSize: "24px", fontWeight: "500" }}>
            OUTSIDER COLORS
          </Typography>

          <Box sx={{ width: "1000px", mt: "16px" }}>
            <Typography sx={{ color: "#222", fontSize: "16px", lineHeight: 1.7}}>
              Our favorite feature by far. Every image generated by <i>Davos</i> has a different probability of outsider colors. Some have very few of them, resulting in a more harmonic output, while others are chaotic, with lots of different colors. Both are beautiful, in their own ways. Some palettes, when combined with a high probability of outsider colors, result in an outstandingly beautiful chaotic output:
            </Typography>
          </Box>

          <Box sx={{ width: "1000px", height: "500px", mt: "24px", mb: "40px", px: "24%" }}>
            <Image src={require("../../assets/Davos/outsider_color.png")} height="500" width="500" />
          </Box>

          <Box sx={{ width: "1000px", mt: "0px" }}>
            <Typography sx={{ color: "#222", fontSize: "16px", lineHeight: 1.7}}>
              The harmonic results, using less outsider colors, are more stable, while the chaotic results are great with some palettes and awful with others. Still, we found them the most interesting feature in the whole algorithm.
            </Typography>
            <Typography sx={{ color: "#222", fontSize: "16px", mt: "12px", lineHeight: 1.7}}>
              Moreover, in very rare cases, the background color can also be an outsider color, which causes the output to be particularly showy.
            </Typography>
          </Box>
          </div>

          <div>
          <Typography sx={{ mt: "72px", color: "#222", fontSize: "24px", fontWeight: "500" }}>
            CIRCLES
          </Typography>

          <Box sx={{ width: "1000px", mt: "16px" }}>
            <Typography sx={{ color: "#222", fontSize: "16px", mb: "40px", lineHeight: 1.7}}>
              Another special feature of <i>Davos</i> is its circles. As mentined before, when a polar projection is used for a shape, its format might resamble a circle. Some images contain no circles, while others are full of them.
            </Typography>
          </Box>

          <Box sx={{ width: "1000px", height: "360px", mt: "16px", mb: "40px" }}>
            <Stack direction="row" justifyContent="space-evenly" sx={{ ml: "-36%" }}>
              <Image src={require("../../assets/Davos/no_circles.png")} height="360" width="360" />
              <Image src={require("../../assets/Davos/circles.png")} height="360" width="360" />
            </Stack>
          </Box>

          <Box sx={{ width: "1000px", mt: "0px" }}>
            <Typography sx={{ color: "#222", fontSize: "16px", lineHeight: 1.7}}>
              The left image has no circles, and the right one has 46 of them. As you can see, their absence does not make the output inferior, there are many amazing results with no circles, but surely in some cases the circles give a final touch to the image.
            </Typography>
          </Box>
          </div>

          <div>
          <Typography sx={{ mt: "72px", color: "#222", fontSize: "24px", fontWeight: "500" }}>
            DISPOSITION
          </Typography>

          <Box sx={{ width: "1000px", mt: "16px", mb: "40px" }}>
            <Typography sx={{ color: "#222", fontSize: "16px", lineHeight: 1.7}}>
              There are two possibilities for the positioning of the shapes. Just by looking at it, one can say whether the shapes are centered or spreaded:
            </Typography>
          </Box>

          <Box sx={{ width: "1000px", height: "360px", mt: "16px", mb: "40px" }}>
            <Stack direction="row" justifyContent="space-evenly" sx={{ ml: "-36%" }}>
              <Image src={require("../../assets/Davos/centered.png")} height="360" width="360" />
              <Image src={require("../../assets/Davos/spreaded.png")} height="360" width="360" />
            </Stack>
          </Box>

          <Box sx={{ width: "1000px", mt: "0px" }}>
            <Typography sx={{ color: "#222", fontSize: "16px", lineHeight: 1.7}}>
              Despite being only a difference of positioning, it gives the output a totally different appearance. Both are equally rare and beautiful. While the spreaded ones are very similar to tradicional abstract art, we like to think about the centered ones as supernovae or nuclei of atoms.
            </Typography>
          </Box>
          </div>

          <div>
          <Typography sx={{ mt: "72px", color: "#222", fontSize: "24px", fontWeight: "500" }}>
            PROJECT CONTRIBUTION
          </Typography>

          <Box sx={{ width: "1000px", mt: "16px", mb: "40px" }}>
            <Typography sx={{ color: "#222", fontSize: "16px", lineHeight: 1.7}}>
              It is everyones responsability to do good deeds and contribute to a better world. This project is one way for us to contribute to many important causes around the world. That is why we will be donating at least 20% of our profit from all our collections to selected charity fundations, that will be listed on the <a href="/about" target="_blank" rel="noopener noreferrer">about page</a> very soon. We will focus our donations to fight hunger and to improve education on poor countries.
            </Typography>
          </Box>
          </div>

          <div>
          <Typography sx={{ mt: "72px", color: "#222", fontSize: "24px", fontWeight: "500" }}>
            INTERACTIVE WEB SIMULATION
          </Typography>

          <Box sx={{ width: "1000px", mt: "16px", mb: "40px" }}>
            <Typography sx={{ color: "#222", fontSize: "16px", lineHeight: 1.7}}>
              For the sake of demonstration, we created a web simulation of <i>Davos</i>, where the user can interact with it. Of course it does not have the same quality of the original images, otherwise it would take a long time to render, but it's perfect for demonstrating how the creation process works. In this case, the shapes were pre created in lower resolution and only some color palettes are avaliable. The shapes are merged on top of the websites normal background, constantly loading new shapes and watching for a user click to insert another shape on the clicked spot. This demonstration was developed using p5.js library. Check it out by clicking in <a href="/" target="_blank" rel="noopener noreferrer">here</a>. Don't forget to try some clicks on it, have fun!
            </Typography>
          </Box>
          </div>
          
        </Box>
      </Desktop>

      <Tablet>
        <Box sx={{ px: "10%", mt: "40px", mb: "0px" }}>

          <div>
          <Box sx={{ width: "300px", height: "70px" }}>
            <Typography sx={{ color: "#222", fontSize: "32px", fontWeight: "500" }}>
              DAVOS
            </Typography>
          </Box>
          
          <Box sx={{ width: "100%", height: "400px", mt: "24px", mb: "40px", ml: "0.5%" }}>
            <Image src={require("../../assets/Davos/initial.png")} height="400" width="400" />
          </Box>

          <Box sx={{ width: "100%", height: "45%" }}>
            <Typography sx={{ color: "#222", fontSize: "14px", mt: "0px", lineHeight: 1.7 }}>
              <i>Davos</i> is the best generative algorithm we have developed so far. Written in Python, it combines multiple layers from different colors to create awesome results. By using lots of shapes, colors and composition styles, it is capable of generating endless outputs, all of them totally different from each other. What makes <i>Davos</i> so different from other generative algorithms is that the shapes used in the compositions do not follow some pattern, and certanly can't be categorized as linear shapes. Their organic format is due to the projection of three dimensional functions in the two dimensional plane. After combining hundreds or thousands of those projections, the resulting output is an outstandingly beautiful image, a vivid mixture of colors and formats. <br /><a href="/" target="_blank" rel="noopener noreferrer">Check out a live and interactive web simulation of Davos <OpenInNewRoundedIcon sx={{ fontSize: "17px", mt: "-2px" }} /></a>
            </Typography>
            <Typography sx={{ color: "#222", fontSize: "14px", mt: "12px", lineHeight: 1.7 }}>
              Now let's dive into the special features and the variety of output that <i>Davos</i> has to offer.
            </Typography>
          </Box>
          </div>

          <div>
          <Typography sx={{ pt: "60px", color: "#222", fontSize: "22px", fontWeight: "500" }}>
            LAYER BY LAYER
          </Typography>

          <Box sx={{ width: "100%", mt: "16px", mb: "20px" }}>
            <Typography sx={{ color: "#222", fontSize: "14px", lineHeight: 1.7}}>
              Every <i>Davos</i> output is composed by multiple layers. Each layer is a projection of a three dimensional function in a single color, with variable opacity. For the projection we use a combination of two advanced mathematical functions, both choosen randomly from a big set of different functions, using random parameters and random projection methods. This is how single layers looks like:
            </Typography>
          </Box>

          <Box sx={{ width: "100%", height: "200px" }}>
            <Stack direction="row" justifyContent="space-evenly" sx={{ ml: "-40%" }}>
              <Image src={require("../../assets/Davos/shapes1.png")} height="180" width="180" />
              <Image src={require("../../assets/Davos/shapes2.png")} height="180" width="180" />
              <Image src={require("../../assets/Davos/shapes3.png")} height="180" width="180" />
            </Stack>
          </Box>

          <Box sx={{ width: "100%", mb: "20px" }}>
            <Typography sx={{ color: "#222", fontSize: "14px", lineHeight: 1.7}}>
              The vast majority of this shapes are rectlinear projections, but there are lots of them using different projection methods as well. One of this methods, that deserves special enphasis, is the polar projection, which generates shapes that resemble circles:
            </Typography>
          </Box>

          <Box sx={{ width: "100%", height: "220px" }}>
            <Stack direction="row" justifyContent="space-evenly" sx={{ ml: "-35%" }}>
              <Image src={require("../../assets/Davos/circular1.png")} height="200" width="200" />
              <Image src={require("../../assets/Davos/circular2.png")} height="200" width="200" />
            </Stack>
          </Box>

          <Box sx={{ width: "100%", mb: "40px" }}>
            <Typography sx={{ color: "#222", fontSize: "14px", lineHeight: 1.7}}>
              The algorithm generates a large quantity of this layers, in different colors and formats and then combine them with the colorful background and <i>voilà</i>, we get some awesome output.
            </Typography>
            <Typography sx={{ color: "#222", fontSize: "14px", mt: "12px", lineHeight: 1.7}}>
              Now that we saw the algorithm's generation process, we shall dive into all the variations it can produce.
            </Typography>
          </Box>
          </div>

          <div>
          <Typography sx={{ mt: "72px", color: "#222", fontSize: "22px", fontWeight: "500" }}>
            COLOR PALETTES
          </Typography>

          <Box sx={{ width: "100%", mt: "14px", mb: "40px" }}>
            <Typography sx={{ color: "#222", fontSize: "14px", lineHeight: 1.7}}>
              <i>Davos</i> was programmed with 37 different color palettes, each of them with only five colors. Only five colors? How is the output so colorful then?
            </Typography>
            <Typography sx={{ color: "#222", fontSize: "14px", mt: "12px", lineHeight: 1.7}}>
              The colors used by the algorithm are not limited by the selected palette. The color palettes are only used to define a basis for the output. For a better understanding, lets take a look at the creation process, from a coloring perspective: 
            </Typography>
            <Typography sx={{ color: "#222", fontSize: "14px", mt: "12px", lineHeight: 1.7}}>
              At first, the algorithm gets a random palette, selects one color from it and uses it as the background color. In the next stage, it generates the shapes to be merged with the background, using one of the four restant colors, choosing each shapes color based on a probabilistic distribution. Every time <i>Davos</i> generates a shape, there is a possibility that it will select a random color for it, instead of using one from the pallete. This phenomenon grants the output some extra vitality, those outsider colors are the ones responsible for its brightness. Here are some examples of its 37 different palettes:
            </Typography>
          </Box>

          <Box sx={{ width: "100%", height: "1700px" }}>
            <div>
            <Stack direction="row" justifyContent="space-evenly" sx={{ ml: "-25%", pb: "125px" }}>
              <Image src={require("../../assets/Davos/palette_samples/agate.png")} height="120" width="120" />
              <Image src={require("../../assets/Davos/palette_samples/alexandrite.png")} height="120" width="120" />
              <Image src={require("../../assets/Davos/palette_samples/amazonite.png")} height="120" width="120" />
              <Image src={require("../../assets/Davos/palette_samples/amber.png")} height="120" width="120" />
            </Stack>
            <Stack direction="row" justifyContent="space-evenly" sx={{ ml: "0%", mt: "0px", mb: "30px" }}>
              <Box height="100" width="25%"><Typography sx={{fontSize: "13px"}}>Agate</Typography></Box>
              <Box height="100" width="25%"><Typography sx={{fontSize: "13px"}}>Alexandrite</Typography></Box>
              <Box height="100" width="25%"><Typography sx={{fontSize: "13px"}}>Amazonite</Typography></Box>
              <Box height="100" width="25%"><Typography sx={{fontSize: "13px"}}>Amber</Typography></Box>
            </Stack>
            </div>

            <div>
            <Stack direction="row" justifyContent="space-evenly" sx={{ ml: "-25%", pb: "125px" }}>
              <Image src={require("../../assets/Davos/palette_samples/amethyst.png")} height="120" width="120" />
              <Image src={require("../../assets/Davos/palette_samples/ametrine.png")} height="120" width="120" />
              <Image src={require("../../assets/Davos/palette_samples/anyolite.png")} height="120" width="120" />
              <Image src={require("../../assets/Davos/palette_samples/apatite.png")} height="120" width="120" />
            </Stack>
            <Stack direction="row" justifyContent="space-evenly" sx={{ ml: "0%", mt: "0px", mb: "30px" }}>
              <Box height="100" width="25%"><Typography sx={{fontSize: "13px"}}>Amethyst</Typography></Box>
              <Box height="100" width="25%"><Typography sx={{fontSize: "13px"}}>Ametrine</Typography></Box>
              <Box height="100" width="25%"><Typography sx={{fontSize: "13px"}}>Anyolite</Typography></Box>
              <Box height="100" width="25%"><Typography sx={{fontSize: "13px"}}>Apatite</Typography></Box>
            </Stack>
            </div>

            <div>
            <Stack direction="row" justifyContent="space-evenly" sx={{ ml: "-25%", pb: "125px" }}>
              <Image src={require("../../assets/Davos/palette_samples/aventurine.png")} height="120" width="120" />
              <Image src={require("../../assets/Davos/palette_samples/azurite.png")} height="120" width="120" />
              <Image src={require("../../assets/Davos/palette_samples/beryl.png")} height="120" width="120" />
              <Image src={require("../../assets/Davos/palette_samples/chrysoprase.png")} height="120" width="120" />
            </Stack>
            <Stack direction="row" justifyContent="space-evenly" sx={{ ml: "0%", mt: "0px", mb: "30px" }}>
              <Box height="100" width="25%"><Typography sx={{fontSize: "13px"}}>Aventurine</Typography></Box>
              <Box height="100" width="25%"><Typography sx={{fontSize: "13px"}}>Azurite</Typography></Box>
              <Box height="100" width="25%"><Typography sx={{fontSize: "13px"}}>Beryl</Typography></Box>
              <Box height="100" width="25%"><Typography sx={{fontSize: "13px"}}>Chrysoprase</Typography></Box>
            </Stack>
            </div>

            <div>
            <Stack direction="row" justifyContent="space-evenly" sx={{ ml: "-25%", pb: "125px" }}>
              <Image src={require("../../assets/Davos/palette_samples/citrine.png")} height="120" width="120" />
              <Image src={require("../../assets/Davos/palette_samples/emerald.png")} height="120" width="120" />
              <Image src={require("../../assets/Davos/palette_samples/fluorite.png")} height="120" width="120" />
              <Image src={require("../../assets/Davos/palette_samples/goshenite.png")} height="120" width="120" />
            </Stack>
            <Stack direction="row" justifyContent="space-evenly" sx={{ ml: "0%", mt: "0px", mb: "30px" }}>
              <Box height="100" width="25%"><Typography sx={{fontSize: "13px"}}>Citrine</Typography></Box>
              <Box height="100" width="25%"><Typography sx={{fontSize: "13px"}}>Emerald</Typography></Box>
              <Box height="100" width="25%"><Typography sx={{fontSize: "13px"}}>Fluorite</Typography></Box>
              <Box height="100" width="25%"><Typography sx={{fontSize: "13px"}}>Goshenite</Typography></Box>
            </Stack>
            </div>

            <div>
            <Stack direction="row" justifyContent="space-evenly" sx={{ ml: "-25%", pb: "125px" }}>
              <Image src={require("../../assets/Davos/palette_samples/heliodor.png")} height="120" width="120" />
              <Image src={require("../../assets/Davos/palette_samples/hematite.png")} height="120" width="120" />
              <Image src={require("../../assets/Davos/palette_samples/jade.png")} height="120" width="120" />
              <Image src={require("../../assets/Davos/palette_samples/jasper.png")} height="120" width="120" />
            </Stack>
            <Stack direction="row" justifyContent="space-evenly" sx={{ ml: "0%", mt: "0px", mb: "30px" }}>
              <Box height="100" width="25%"><Typography sx={{fontSize: "13px"}}>Heliodor</Typography></Box>
              <Box height="100" width="25%"><Typography sx={{fontSize: "13px"}}>Hematite</Typography></Box>
              <Box height="100" width="25%"><Typography sx={{fontSize: "13px"}}>Jade</Typography></Box>
              <Box height="100" width="25%"><Typography sx={{fontSize: "13px"}}>Jasper</Typography></Box>
            </Stack>
            </div>

            <div>
            <Stack direction="row" justifyContent="space-evenly" sx={{ ml: "-25%", pb: "125px" }}>
              <Image src={require("../../assets/Davos/palette_samples/kunzite.png")} height="120" width="120" />
              <Image src={require("../../assets/Davos/palette_samples/lepidolite.png")} height="120" width="120" />
              <Image src={require("../../assets/Davos/palette_samples/malachite.png")} height="120" width="120" />
              <Image src={require("../../assets/Davos/palette_samples/moonstone.png")} height="120" width="120" />
            </Stack>
            <Stack direction="row" justifyContent="space-evenly" sx={{ ml: "0%", mt: "0px", mb: "20px" }}>
              <Box height="100" width="25%"><Typography sx={{fontSize: "13px"}}>Kunzite</Typography></Box>
              <Box height="100" width="25%"><Typography sx={{fontSize: "13px"}}>Lepidolite</Typography></Box>
              <Box height="100" width="25%"><Typography sx={{fontSize: "13px"}}>Malachite</Typography></Box>
              <Box height="100" width="25%"><Typography sx={{fontSize: "13px"}}>Moonstone</Typography></Box>
            </Stack>
            </div>

            <div>
            <Stack direction="row" justifyContent="space-evenly" sx={{ ml: "-25%", pb: "125px" }}>
              <Image src={require("../../assets/Davos/palette_samples/onyx.png")} height="120" width="120" />
              <Image src={require("../../assets/Davos/palette_samples/opal.png")} height="120" width="120" />
              <Image src={require("../../assets/Davos/palette_samples/pearl.png")} height="120" width="120" />
              <Image src={require("../../assets/Davos/palette_samples/quartz.png")} height="120" width="120" />
            </Stack>
            <Stack direction="row" justifyContent="space-evenly" sx={{ ml: "0%", mt: "0px", mb: "30px" }}>
              <Box height="100" width="25%"><Typography sx={{fontSize: "13px"}}>Onyx</Typography></Box>
              <Box height="100" width="25%"><Typography sx={{fontSize: "13px"}}>Opal</Typography></Box>
              <Box height="100" width="25%"><Typography sx={{fontSize: "13px"}}>Pearl</Typography></Box>
              <Box height="100" width="25%"><Typography sx={{fontSize: "13px"}}>Quartz</Typography></Box>
            </Stack>
            </div>

            <div>
            <Stack direction="row" justifyContent="space-evenly" sx={{ ml: "-25%", pb: "125px" }}>
              <Image src={require("../../assets/Davos/palette_samples/rhodonite.png")} height="120" width="120" />
              <Image src={require("../../assets/Davos/palette_samples/ruby.png")} height="120" width="120" />
              <Image src={require("../../assets/Davos/palette_samples/sapphire.png")} height="120" width="120" />
              <Image src={require("../../assets/Davos/palette_samples/sphalerite.png")} height="120" width="120" />
            </Stack>
            <Stack direction="row" justifyContent="space-evenly" sx={{ ml: "0%", mt: "0px", mb: "30px" }}>
              <Box height="100" width="25%"><Typography sx={{fontSize: "13px"}}>Rhodonite</Typography></Box>
              <Box height="100" width="25%"><Typography sx={{fontSize: "13px"}}>Ruby</Typography></Box>
              <Box height="100" width="25%"><Typography sx={{fontSize: "13px"}}>Sapphire</Typography></Box>
              <Box height="100" width="25%"><Typography sx={{fontSize: "13px"}}>Sphalerite</Typography></Box>
            </Stack>
            </div>

            <div>
            <Stack direction="row" justifyContent="space-evenly" sx={{ ml: "-25%", pb: "125px" }}>
              <Image src={require("../../assets/Davos/palette_samples/sugilite.png")} height="120" width="120" />
              <Image src={require("../../assets/Davos/palette_samples/tanzanite.png")} height="120" width="120" />
              <Image src={require("../../assets/Davos/palette_samples/variscite.png")} height="120" width="120" />
              <Image src={require("../../assets/Davos/palette_samples/wonderstone.png")} height="120" width="120" />
            </Stack>
            <Stack direction="row" justifyContent="space-evenly" sx={{ ml: "0%", mt: "0px", mb: "30px" }}>
              <Box height="100" width="25%"><Typography sx={{fontSize: "13px"}}>Sugilite</Typography></Box>
              <Box height="100" width="25%"><Typography sx={{fontSize: "13px"}}>Tanzanite</Typography></Box>
              <Box height="100" width="25%"><Typography sx={{fontSize: "13px"}}>Variscite</Typography></Box>
              <Box height="100" width="25%"><Typography sx={{fontSize: "13px"}}>Wonderstone</Typography></Box>
            </Stack>
            </div>

            <div>
            <Stack direction="row" justifyContent="space-evenly" sx={{ ml: "-25%", pb: "125px" }}>
              <Image src={require("../../assets/Davos/palette_samples/zircon.png")} height="120" width="120" />
              <Box height="120" width="120"></Box>
              <Box height="120" width="120"></Box>
              <Box height="120" width="120"></Box>
            </Stack>
            <Stack direction="row" justifyContent="space-evenly" sx={{ ml: "0%", mt: "0px", mb: "30px" }}>
              <Box height="100" width="25%"><Typography sx={{fontSize: "13px"}}>Zircon</Typography></Box>
              <Box height="100" width="25%"><Typography sx={{fontSize: "13px"}}></Typography></Box>
              <Box height="100" width="25%"><Typography sx={{fontSize: "13px"}}></Typography></Box>
              <Box height="100" width="25%"><Typography sx={{fontSize: "13px"}}></Typography></Box>
            </Stack>
            </div>
          </Box>

          <Box sx={{ width: "100%", mt: "30px" }}>
            <Typography sx={{ color: "#222", fontSize: "14px", lineHeight: 1.7}}>
              Apart from the color palette selection, there are many other key factors that have a strong influence on the outputs coloring. We will now get further into all this features, one by one.
            </Typography>
          </Box>
          </div>

          <div>
          <Typography sx={{ mt: "72px", color: "#222", fontSize: "22px", fontWeight: "500" }}>
            COLOR DISTRIBUTION
          </Typography>

          <Box sx={{ width: "100%", mt: "16px", mb: "0px" }}>
            <Typography sx={{ color: "#222", fontSize: "14px", lineHeight: 1.7}}>
              As mentioned before, after the algorithm selects a background color, there are four colors left that will be used to generate most of the shapes. But in fact, before starting to generate the shapes, it defines a probabilistic color distribution; the proportion of each color on the final output. There are five color distributions used by the algorithm: 
            </Typography>

            <ul>
              <li>
                <Typography sx={{ color: "#222", fontSize: "14px", mt: "10px", lineHeight: 1.7}}>
                  <span>•</span> Sonata - One predominant color, others with a small proportion
                </Typography>
              </li>
              <li>
                <Typography sx={{ color: "#222", fontSize: "14px", mt: "10px", lineHeight: 1.7}}>
                  <span>•</span> Concerto - One main color, others with a medium or small proportion
                </Typography>
              </li>
              <li>
                <Typography sx={{ color: "#222", fontSize: "14px", mt: "10px", lineHeight: 1.7}}>
                  <span>•</span> Cantata - Similar color proportions, with small dispartions
                </Typography>
              </li>
              <li>
                <Typography sx={{ color: "#222", fontSize: "14px", mt: "10px", lineHeight: 1.7}}>
                  <span>•</span> Fugue - Two main colors and other two with minor participation
                </Typography>
              </li>
              <li>
                <Typography sx={{ color: "#222", fontSize: "14px", mt: "10px", lineHeight: 1.7}}>
                  <span>•</span> Symphony - All colors in the same proportion
                </Typography>
              </li>
            </ul>

            <Typography sx={{ color: "#222", fontSize: "14px", mt: "30px", lineHeight: 1.7}}>
              The differences between the distribution are subtle, but have a strong impact on the final result. Eventhough the two images bellow use the same color palette and the same background color, as you can see, there are clearly different proportions of colors in each of them:
            </Typography>
          </Box>

          <Box sx={{ width: "100%", height: "220px", mt: "30px", mb: "10px" }}>
            <Stack direction="row" justifyContent="space-evenly" sx={{ ml: "-40%" }}>
              <Image src={require("../../assets/Davos/distribution1.png")} height="200" width="200" />
              <Image src={require("../../assets/Davos/distribution2.png")} height="200" width="200" />
            </Stack>
          </Box>

          <Box sx={{ width: "100%", mt: "0px" }}>
            <Typography sx={{ color: "#222", fontSize: "14px", lineHeight: 1.7}}>
              Even using the same palette and the same color distribution there is still great variety in coloration, once the proportions of that distribution can be assigned to different colors.
            </Typography>
          </Box>
          </div>

          <div>
          <Typography sx={{ mt: "72px", color: "#222", fontSize: "24px", fontWeight: "500" }}>
            OUTSIDER COLORS
          </Typography>

          <Box sx={{ width: "100%", mt: "16px" }}>
            <Typography sx={{ color: "#222", fontSize: "14px", lineHeight: 1.7}}>
              Our favorite feature by far. Every image generated by <i>Davos</i> has a different probability of outsider colors. Some have very few of them, resulting in a more harmonic output, while others are chaotic, with lots of different colors. Both are beautiful, in their own ways. Some palettes, when combined with a high probability of outsider colors, result in an outstandingly beautiful chaotic output:
            </Typography>
          </Box>

          <Box sx={{ width: "100%", height: "300px", mt: "24px", mb: "40px", ml: "18%" }}>
            <Image src={require("../../assets/Davos/outsider_color.png")} height="300" width="300" />
          </Box>

          <Box sx={{ width: "100%", mt: "0px" }}>
            <Typography sx={{ color: "#222", fontSize: "14px", lineHeight: 1.7}}>
              The harmonic results, using less outsider colors, are more stable, while the chaotic results are great with some palettes and awful with others. Still, we found them the most interesting feature in the whole algorithm.
            </Typography>
            <Typography sx={{ color: "#222", fontSize: "14px", mt: "12px", lineHeight: 1.7}}>
              Moreover, in very rare cases, the background color can also be an outsider color, which causes the output to be particularly showy.
            </Typography>
          </Box>
          </div>

          <div>
          <Typography sx={{ mt: "72px", color: "#222", fontSize: "22px", fontWeight: "500" }}>
            CIRCLES
          </Typography>

          <Box sx={{ width: "100%", mt: "16px" }}>
            <Typography sx={{ color: "#222", fontSize: "14px", mb: "40px", lineHeight: 1.7}}>
              Another special feature of <i>Davos</i> is its circles. As mentined before, when a polar projection is used for a shape, its format might resamble a circle. Some images contain no circles, while others are full of them.
            </Typography>
          </Box>

          <Box sx={{ width: "100%", height: "220px", mt: "20px", mb: "10px" }}>
            <Stack direction="row" justifyContent="space-evenly" sx={{ ml: "-40%" }}>
              <Image src={require("../../assets/Davos/no_circles.png")} height="200" width="200" />
              <Image src={require("../../assets/Davos/circles.png")} height="200" width="200" />
            </Stack>
          </Box>

          <Box sx={{ width: "100%", mt: "0px" }}>
            <Typography sx={{ color: "#222", fontSize: "14px", lineHeight: 1.7}}>
              The left image has no circles, and the right one has 46 of them. As you can see, their absence does not make the output inferior, there are many amazing results with no circles, but surely in some cases the circles give a final touch to the image.
            </Typography>
          </Box>
          </div>

          <div>
          <Typography sx={{ mt: "72px", color: "#222", fontSize: "22px", fontWeight: "500" }}>
            DISPOSITION
          </Typography>

          <Box sx={{ width: "100%", mt: "16px", mb: "40px" }}>
            <Typography sx={{ color: "#222", fontSize: "14px", lineHeight: 1.7}}>
              There are two possibilities for the positioning of the shapes. Just by looking at it, one can say whether the shapes are centered or spreaded:
            </Typography>
          </Box>

          <Box sx={{ width: "100%", height: "220px", mt: "20px", mb: "10px" }}>
            <Stack direction="row" justifyContent="space-evenly" sx={{ ml: "-40%" }}>
              <Image src={require("../../assets/Davos/centered.png")} height="200" width="200" />
              <Image src={require("../../assets/Davos/spreaded.png")} height="200" width="200" />
            </Stack>
          </Box>

          <Box sx={{ width: "100%", mt: "0px" }}>
            <Typography sx={{ color: "#222", fontSize: "14px", lineHeight: 1.7}}>
              Despite being only a difference of positioning, it gives the output a totally different appearance. Both are equally rare and beautiful. While the spreaded ones are very similar to tradicional abstract art, we like to think about the centered ones as supernovae or nuclei of atoms.
            </Typography>
          </Box>
          </div>

          <div>
          <Typography sx={{ mt: "72px", color: "#222", fontSize: "22px", fontWeight: "500" }}>
            PROJECT CONTRIBUTION
          </Typography>

          <Box sx={{ width: "100%", mt: "16px", mb: "40px" }}>
            <Typography sx={{ color: "#222", fontSize: "14px", lineHeight: 1.7}}>
              It is everyones responsability to do good deeds and contribute to a better world. This project is one way for us to contribute to many important causes around the world. That is why we will be donating at least 20% of our profit from all our collections to selected charity fundations, that will be listed on the <a href="/about" target="_blank" rel="noopener noreferrer">about page</a> very soon. We will focus our donations to fight hunger and to improve education on poor countries.
            </Typography>
          </Box>
          </div>

          <div>
          <Typography sx={{ mt: "72px", color: "#222", fontSize: "22px", fontWeight: "500" }}>
            INTERACTIVE WEB SIMULATION
          </Typography>

          <Box sx={{ width: "100%", mt: "16px", mb: "40px" }}>
            <Typography sx={{ color: "#222", fontSize: "14px", lineHeight: 1.7}}>
              For the sake of demonstration, we created a web simulation of <i>Davos</i>, where the user can interact with it. Of course it does not have the same quality of the original images, otherwise it would take a long time to render, but it's perfect for demonstrating how the creation process works. In this case, the shapes were pre created in lower resolution and only some color palettes are avaliable. The shapes are merged on top of the websites normal background, constantly loading new shapes and watching for a user click to insert another shape on the clicked spot. This demonstration was developed using p5.js library. Check it out by clicking in <a href="/" target="_blank" rel="noopener noreferrer">here</a>. Don't forget to try some clicks on it, have fun!
            </Typography>
          </Box>
          </div>
          
        </Box>
      </Tablet>

      <Mobile>
        <Box sx={{ px: "8%", mt: "80px", mb: "0px" }}>

        <div>
        <Box sx={{ width: "300px", height: "70px" }}>
          <Typography sx={{ color: "#222", fontSize: "30px", fontWeight: "500" }}>
            DAVOS
          </Typography>
        </Box>

        <Box sx={{ width: "100%", height: "300px", mt: "24px", mb: "30px", ml: "0.5%" }}>
          <Image src={require("../../assets/Davos/initial.png")} height="300" width="300" />
        </Box>

        <Box sx={{ width: "100%", height: "40%" }}>
          <Typography sx={{ color: "#222", fontSize: "14px", mt: "0px", lineHeight: 1.7 }}>
            <i>Davos</i> is the best generative algorithm we have developed so far. Written in Python, it combines multiple layers from different colors to create awesome results. By using lots of shapes, colors and composition styles, it is capable of generating endless outputs, all of them totally different from each other. What makes <i>Davos</i> so different from other generative algorithms is that the shapes used in the compositions do not follow some pattern, and certanly can't be categorized as linear shapes. Their organic format is due to the projection of three dimensional functions in the two dimensional plane. After combining hundreds or thousands of those projections, the resulting output is an outstandingly beautiful image, a vivid mixture of colors and formats. <br /><a href="/" target="_blank" rel="noopener noreferrer">Check out a live and interactive web simulation of Davos <OpenInNewRoundedIcon sx={{ fontSize: "17px", mt: "-2px" }} /></a>
          </Typography>
          <Typography sx={{ color: "#222", fontSize: "14px", mt: "16px", lineHeight: 1.7 }}>
            Now let's dive into the special features and the variety of output that <i>Davos</i> has to offer.
          </Typography>
        </Box>
        </div>

        <div>
        <Typography sx={{ pt: "60px", color: "#222", fontSize: "22px", fontWeight: "500" }}>
          LAYER BY LAYER
        </Typography>

        <Box sx={{ width: "100%", mt: "16px", mb: "20px" }}>
          <Typography sx={{ color: "#222", fontSize: "14px", lineHeight: 1.7}}>
            Every <i>Davos</i> output is composed by multiple layers. Each layer is a projection of a three dimensional function in a single color, with variable opacity. For the projection we use a combination of two advanced mathematical functions, both choosen randomly from a big set of different functions, using random parameters and random projection methods. This is how single layers looks like:
          </Typography>
        </Box>

        <Box sx={{ width: "100%", height: "160px" }}>
          <Stack direction="row" justifyContent="space-evenly" sx={{ ml: "-45%" }}>
            <Image src={require("../../assets/Davos/shapes1.png")} height="120" width="120" />
            <Image src={require("../../assets/Davos/shapes2.png")} height="120" width="120" />
            <Image src={require("../../assets/Davos/shapes3.png")} height="120" width="120" />
          </Stack>
        </Box>

        <Box sx={{ width: "100%", mb: "20px" }}>
          <Typography sx={{ color: "#222", fontSize: "14px", lineHeight: 1.7}}>
            The vast majority of this shapes are rectlinear projections, but there are lots of them using different projection methods as well. One of this methods, that deserves special enphasis, is the polar projection, which generates shapes that resemble circles:
          </Typography>
        </Box>

        <Box sx={{ width: "100%", height: "160px" }}>
          <Stack direction="row" justifyContent="space-evenly" sx={{ ml: "-45%" }}>
            <Image src={require("../../assets/Davos/circular1.png")} height="120" width="120" />
            <Image src={require("../../assets/Davos/circular2.png")} height="120" width="120" />
          </Stack>
        </Box>

        <Box sx={{ width: "100%", mb: "40px" }}>
          <Typography sx={{ color: "#222", fontSize: "14px", lineHeight: 1.7}}>
            The algorithm generates a large quantity of this layers, in different colors and formats and then combine them with the colorful background and <i>voilà</i>, we get some awesome output.
          </Typography>
          <Typography sx={{ color: "#222", fontSize: "14px", mt: "12px", lineHeight: 1.7}}>
            Now that we saw the algorithm's generation process, we shall dive into all the variations it can produce.
          </Typography>
        </Box>
        </div>

        <div>
        <Typography sx={{ mt: "72px", color: "#222", fontSize: "22px", fontWeight: "500" }}>
          COLOR PALETTES
        </Typography>

        <Box sx={{ width: "100%", mt: "14px", mb: "20px" }}>
          <Typography sx={{ color: "#222", fontSize: "14px", lineHeight: 1.7}}>
            <i>Davos</i> was programmed with 37 different color palettes, each of them with only five colors. Only five colors? How is the output so colorful then?
          </Typography>
          <Typography sx={{ color: "#222", fontSize: "14px", mt: "12px", lineHeight: 1.7}}>
            The colors used by the algorithm are not limited by the selected palette. The color palettes are only used to define a basis for the output. For a better understanding, lets take a look at the creation process, from a coloring perspective: 
          </Typography>
          <Typography sx={{ color: "#222", fontSize: "14px", mt: "12px", lineHeight: 1.7}}>
            At first, the algorithm gets a random palette, selects one color from it and uses it as the background color. In the next stage, it generates the shapes to be merged with the background, using one of the four restant colors, choosing each shapes color based on a probabilistic distribution. Every time <i>Davos</i> generates a shape, there is a possibility that it will select a random color for it, instead of using one from the pallete. This phenomenon grants the output some extra vitality, those outsider colors are the ones responsible for its brightness. Here are some examples of its 37 different palettes:
          </Typography>
        </Box>

        <Box sx={{ width: "100%", height: "1600px" }}>
          <div>
          <Stack direction="row" justifyContent="space-evenly" sx={{ mt: "16px", ml: "-32%", pb: "92px" }}>
            <Image src={require("../../assets/Davos/palette_samples/agate.png")} height="90" width="90" />
            <Image src={require("../../assets/Davos/palette_samples/alexandrite.png")} height="90" width="90" />
            <Image src={require("../../assets/Davos/palette_samples/amazonite.png")} height="90" width="90" />
          </Stack>
          <Stack direction="row" justifyContent="space-evenly" sx={{ ml: "-5%", mt: "0px", mb: "12px" }}>
            <Box height="20" width="25%"><Typography sx={{fontSize: "13px"}}>Agate</Typography></Box>
            <Box height="20" width="25%"><Typography sx={{fontSize: "13px"}}>Alexandrite</Typography></Box>
            <Box height="20" width="25%"><Typography sx={{fontSize: "13px"}}>Amazonite</Typography></Box>
          </Stack>
          </div>

          <div>
          <Stack direction="row" justifyContent="space-evenly" sx={{ ml: "-32%", pb: "92px" }}>
            <Image src={require("../../assets/Davos/palette_samples/amber.png")} height="90" width="90" />
            <Image src={require("../../assets/Davos/palette_samples/amethyst.png")} height="90" width="90" />
            <Image src={require("../../assets/Davos/palette_samples/ametrine.png")} height="90" width="90" />
          </Stack>
          <Stack direction="row" justifyContent="space-evenly" sx={{ ml: "-5%", mt: "0px", mb: "12px" }}>
            <Box height="20" width="25%"><Typography sx={{fontSize: "13px"}}>Amber</Typography></Box>
            <Box height="20" width="25%"><Typography sx={{fontSize: "13px"}}>Amethyst</Typography></Box>
            <Box height="20" width="25%"><Typography sx={{fontSize: "13px"}}>Ametrine</Typography></Box>
          </Stack>
          </div>

          <div>
          <Stack direction="row" justifyContent="space-evenly" sx={{ ml: "-32%", pb: "92px" }}>
            <Image src={require("../../assets/Davos/palette_samples/anyolite.png")} height="90" width="90" />
            <Image src={require("../../assets/Davos/palette_samples/apatite.png")} height="90" width="90" />
            <Image src={require("../../assets/Davos/palette_samples/aventurine.png")} height="90" width="90" />
          </Stack>
          <Stack direction="row" justifyContent="space-evenly" sx={{ ml: "-5%", mt: "0px", mb: "12px" }}>
            <Box height="20" width="25%"><Typography sx={{fontSize: "13px"}}>Anyolite</Typography></Box>
            <Box height="20" width="25%"><Typography sx={{fontSize: "13px"}}>Apatite</Typography></Box>
            <Box height="20" width="25%"><Typography sx={{fontSize: "13px"}}>Aventurine</Typography></Box>
          </Stack>
          </div>

          <div>
          <Stack direction="row" justifyContent="space-evenly" sx={{ ml: "-32%", pb: "92px" }}>
            <Image src={require("../../assets/Davos/palette_samples/azurite.png")} height="90" width="90" />
            <Image src={require("../../assets/Davos/palette_samples/beryl.png")} height="90" width="90" />
            <Image src={require("../../assets/Davos/palette_samples/chrysoprase.png")} height="90" width="90" />
          </Stack>
          <Stack direction="row" justifyContent="space-evenly" sx={{ ml: "-5%", mt: "0px", mb: "12px" }}>
            <Box height="20" width="25%"><Typography sx={{fontSize: "13px"}}>Azurite</Typography></Box>
            <Box height="20" width="25%"><Typography sx={{fontSize: "13px"}}>Beryl</Typography></Box>
            <Box height="20" width="25%"><Typography sx={{fontSize: "13px"}}>Chrysoprase</Typography></Box>
          </Stack>
          </div>

          <div>
          <Stack direction="row" justifyContent="space-evenly" sx={{ ml: "-32%", pb: "92px" }}>
            <Image src={require("../../assets/Davos/palette_samples/citrine.png")} height="90" width="90" />
            <Image src={require("../../assets/Davos/palette_samples/emerald.png")} height="90" width="90" />
            <Image src={require("../../assets/Davos/palette_samples/fluorite.png")} height="90" width="90" />
          </Stack>
          <Stack direction="row" justifyContent="space-evenly" sx={{ ml: "-5%", mt: "0px", mb: "12px" }}>
            <Box height="20" width="25%"><Typography sx={{fontSize: "13px"}}>Citrine</Typography></Box>
            <Box height="20" width="25%"><Typography sx={{fontSize: "13px"}}>Emerald</Typography></Box>
            <Box height="20" width="25%"><Typography sx={{fontSize: "13px"}}>Fluorite</Typography></Box>
          </Stack>
          </div>

          <div>
          <Stack direction="row" justifyContent="space-evenly" sx={{ ml: "-32%", pb: "92px" }}>
            <Image src={require("../../assets/Davos/palette_samples/goshenite.png")} height="90" width="90" />
            <Image src={require("../../assets/Davos/palette_samples/heliodor.png")} height="90" width="90" />
            <Image src={require("../../assets/Davos/palette_samples/hematite.png")} height="90" width="90" />
          </Stack>
          <Stack direction="row" justifyContent="space-evenly" sx={{ ml: "-5%", mt: "0px", mb: "12px" }}>
            <Box height="20" width="25%"><Typography sx={{fontSize: "13px"}}>Goshenite</Typography></Box>
            <Box height="20" width="25%"><Typography sx={{fontSize: "13px"}}>Heliodor</Typography></Box>
            <Box height="20" width="25%"><Typography sx={{fontSize: "13px"}}>Hematite</Typography></Box>
          </Stack>
          </div>

          <div>
          <Stack direction="row" justifyContent="space-evenly" sx={{ ml: "-32%", pb: "92px" }}>
            <Image src={require("../../assets/Davos/palette_samples/jade.png")} height="90" width="90" />
            <Image src={require("../../assets/Davos/palette_samples/jasper.png")} height="90" width="90" />
            <Image src={require("../../assets/Davos/palette_samples/kunzite.png")} height="90" width="90" />
          </Stack>
          <Stack direction="row" justifyContent="space-evenly" sx={{ ml: "-5%", mt: "0px", mb: "12px" }}>
            <Box height="20" width="25%"><Typography sx={{fontSize: "13px"}}>Jade</Typography></Box>
            <Box height="20" width="25%"><Typography sx={{fontSize: "13px"}}>Jasper</Typography></Box>
            <Box height="20" width="25%"><Typography sx={{fontSize: "13px"}}>Kunzite</Typography></Box>
          </Stack>
          </div>

          <div>
          <Stack direction="row" justifyContent="space-evenly" sx={{ ml: "-32%", pb: "92px" }}>
            <Image src={require("../../assets/Davos/palette_samples/lepidolite.png")} height="90" width="90" />
            <Image src={require("../../assets/Davos/palette_samples/malachite.png")} height="90" width="90" />
            <Image src={require("../../assets/Davos/palette_samples/moonstone.png")} height="90" width="90" />
          </Stack>
          <Stack direction="row" justifyContent="space-evenly" sx={{ ml: "-5%", mt: "0px", mb: "12px" }}>
            <Box height="20" width="25%"><Typography sx={{fontSize: "13px"}}>Lepidolite</Typography></Box>
            <Box height="20" width="25%"><Typography sx={{fontSize: "13px"}}>Malachite</Typography></Box>
            <Box height="20" width="25%"><Typography sx={{fontSize: "13px"}}>Moonstone</Typography></Box>
          </Stack>
          </div>

          <div>
          <Stack direction="row" justifyContent="space-evenly" sx={{ ml: "-32%", pb: "92px" }}>
            <Image src={require("../../assets/Davos/palette_samples/onyx.png")} height="90" width="90" />
            <Image src={require("../../assets/Davos/palette_samples/opal.png")} height="90" width="90" />
            <Image src={require("../../assets/Davos/palette_samples/pearl.png")} height="90" width="90" />
          </Stack>
          <Stack direction="row" justifyContent="space-evenly" sx={{ ml: "-5%", mt: "0px", mb: "12px" }}>
            <Box height="20" width="25%"><Typography sx={{fontSize: "13px"}}>Onyx</Typography></Box>
            <Box height="20" width="25%"><Typography sx={{fontSize: "13px"}}>Opal</Typography></Box>
            <Box height="20" width="25%"><Typography sx={{fontSize: "13px"}}>Pearl</Typography></Box>
          </Stack>
          </div>

          <div>
          <Stack direction="row" justifyContent="space-evenly" sx={{ ml: "-32%", pb: "92px" }}>
            <Image src={require("../../assets/Davos/palette_samples/quartz.png")} height="90" width="90" />
            <Image src={require("../../assets/Davos/palette_samples/rhodonite.png")} height="90" width="90" />
            <Image src={require("../../assets/Davos/palette_samples/ruby.png")} height="90" width="90" />
          </Stack>
          <Stack direction="row" justifyContent="space-evenly" sx={{ ml: "-5%", mt: "0px", mb: "12px" }}>
            <Box height="20" width="25%"><Typography sx={{fontSize: "13px"}}>Quartz</Typography></Box>
            <Box height="20" width="25%"><Typography sx={{fontSize: "13px"}}>Rhodonite</Typography></Box>
            <Box height="20" width="25%"><Typography sx={{fontSize: "13px"}}>Ruby</Typography></Box>
          </Stack>
          </div>

          <div>
          <Stack direction="row" justifyContent="space-evenly" sx={{ ml: "-32%", pb: "92px" }}>
            <Image src={require("../../assets/Davos/palette_samples/sapphire.png")} height="90" width="90" />
            <Image src={require("../../assets/Davos/palette_samples/sphalerite.png")} height="90" width="90" />
            <Image src={require("../../assets/Davos/palette_samples/sugilite.png")} height="90" width="90" />
          </Stack>
          <Stack direction="row" justifyContent="space-evenly" sx={{ ml: "-5%", mt: "0px", mb: "12px" }}>
            <Box height="20" width="25%"><Typography sx={{fontSize: "13px"}}>Sapphire</Typography></Box>
            <Box height="20" width="25%"><Typography sx={{fontSize: "13px"}}>Sphalerite</Typography></Box>
            <Box height="20" width="25%"><Typography sx={{fontSize: "13px"}}>Sugilite</Typography></Box>
          </Stack>
          </div>

          <div>
          <Stack direction="row" justifyContent="space-evenly" sx={{ ml: "-32%", pb: "92px" }}>
            <Image src={require("../../assets/Davos/palette_samples/tanzanite.png")} height="90" width="90" />
            <Image src={require("../../assets/Davos/palette_samples/variscite.png")} height="90" width="90" />
            <Image src={require("../../assets/Davos/palette_samples/wonderstone.png")} height="90" width="90" />
          </Stack>
          <Stack direction="row" justifyContent="space-evenly" sx={{ ml: "-5%", mt: "0px", mb: "12px" }}>
            <Box height="20" width="25%"><Typography sx={{fontSize: "13px"}}>Tanzanite</Typography></Box>
            <Box height="20" width="25%"><Typography sx={{fontSize: "13px"}}>Variscite</Typography></Box>
            <Box height="20" width="25%"><Typography sx={{fontSize: "13px"}}>Wonderstone</Typography></Box>
          </Stack>
          </div>

          <div>
          <Stack direction="row" justifyContent="space-evenly" sx={{ ml: "-32%", pb: "92px" }}>
            <Image src={require("../../assets/Davos/palette_samples/zircon.png")} height="90" width="90" />
            <Box height="90" width="90"></Box>
            <Box height="90" width="90"></Box>
          </Stack>
          <Stack direction="row" justifyContent="space-evenly" sx={{ ml: "-5%", mt: "0px", mb: "12px" }}>
            <Box height="150" width="25%"><Typography sx={{fontSize: "13px"}}>Zircon</Typography></Box>
            <Box height="150" width="25%"><Typography sx={{fontSize: "13px"}}></Typography></Box>
            <Box height="150" width="25%"><Typography sx={{fontSize: "13px"}}></Typography></Box>
          </Stack>
          </div>
        </Box>

        <Box sx={{ width: "100%", mt: "10px" }}>
          <Typography sx={{ color: "#222", fontSize: "14px", lineHeight: 1.7}}>
            Apart from the color palette selection, there are many other key factors that have a strong influence on the outputs coloring. We will now get further into all this features, one by one.
          </Typography>
        </Box>
        </div>

        <div>
        <Typography sx={{ mt: "72px", color: "#222", fontSize: "22px", fontWeight: "500" }}>
          COLOR DISTRIBUTION
        </Typography>

        <Box sx={{ width: "100%", mt: "16px", mb: "0px" }}>
          <Typography sx={{ color: "#222", fontSize: "14px", lineHeight: 1.7}}>
            As mentioned before, after the algorithm selects a background color, there are four colors left that will be used to generate most of the shapes. But in fact, before starting to generate the shapes, it defines a probabilistic color distribution; the proportion of each color on the final output. There are five color distributions used by the algorithm: 
          </Typography>

          <Box ml="-18px">
          <ul>
            <li>
              <Typography sx={{ color: "#222", fontSize: "14px", mt: "10px", lineHeight: 1.7}}>
                <span>•</span> Sonata - One predominant color, others with a small proportion
              </Typography>
            </li>
            <li>
              <Typography sx={{ color: "#222", fontSize: "14px", mt: "10px", lineHeight: 1.7}}>
                <span>•</span> Concerto - One main color, others with a medium or small proportion
              </Typography>
            </li>
            <li>
              <Typography sx={{ color: "#222", fontSize: "14px", mt: "10px", lineHeight: 1.7}}>
                <span>•</span> Cantata - Similar color proportions, with small dispartions
              </Typography>
            </li>
            <li>
              <Typography sx={{ color: "#222", fontSize: "14px", mt: "10px", lineHeight: 1.7}}>
                <span>•</span> Fugue - Two main colors and other two with minor participation
              </Typography>
            </li>
            <li>
              <Typography sx={{ color: "#222", fontSize: "14px", mt: "10px", lineHeight: 1.7}}>
                <span>•</span> Symphony - All colors in the same proportion
              </Typography>
            </li>
          </ul>
          </Box>

          <Typography sx={{ color: "#222", fontSize: "14px", mt: "30px", lineHeight: 1.7}}>
            The differences between the distribution are subtle, but have a strong impact on the final result. Eventhough the two images bellow use the same color palette and the same background color, as you can see, there are clearly different proportions of colors in each of them:
          </Typography>
        </Box>

        <Box sx={{ width: "100%", height: "150px", mt: "30px", mb: "10px" }}>
          <Stack direction="row" justifyContent="space-evenly" sx={{ ml: "-50%" }}>
            <Image src={require("../../assets/Davos/distribution1.png")} height="130" width="130" />
            <Image src={require("../../assets/Davos/distribution2.png")} height="130" width="130" />
          </Stack>
        </Box>

        <Box sx={{ width: "100%", mt: "0px" }}>
          <Typography sx={{ color: "#222", fontSize: "14px", lineHeight: 1.7}}>
            Even using the same palette and the same color distribution there is still great variety in coloration, once the proportions of that distribution can be assigned to different colors.
          </Typography>
        </Box>
        </div>

        <div>
        <Typography sx={{ mt: "72px", color: "#222", fontSize: "24px", fontWeight: "500" }}>
          OUTSIDER COLORS
        </Typography>

        <Box sx={{ width: "100%", mt: "16px" }}>
          <Typography sx={{ color: "#222", fontSize: "14px", lineHeight: 1.7}}>
            Our favorite feature by far. Every image generated by <i>Davos</i> has a different probability of outsider colors. Some have very few of them, resulting in a more harmonic output, while others are chaotic, with lots of different colors. Both are beautiful, in their own ways. Some palettes, when combined with a high probability of outsider colors, result in an outstandingly beautiful chaotic output:
          </Typography>
        </Box>

        <Box sx={{ width: "100%", height: "280px", mt: "24px", mb: "40px", ml: "0%" }}>
          <Image src={require("../../assets/Davos/outsider_color.png")} height="280" width="280" />
        </Box>

        <Box sx={{ width: "100%", mt: "0px" }}>
          <Typography sx={{ color: "#222", fontSize: "14px", lineHeight: 1.7}}>
            The harmonic results, using less outsider colors, are more stable, while the chaotic results are great with some palettes and awful with others. Still, we found them the most interesting feature in the whole algorithm.
          </Typography>
          <Typography sx={{ color: "#222", fontSize: "14px", mt: "12px", lineHeight: 1.7}}>
            Moreover, in very rare cases, the background color can also be an outsider color, which causes the output to be particularly showy.
          </Typography>
        </Box>
        </div>

        <div>
        <Typography sx={{ mt: "72px", color: "#222", fontSize: "22px", fontWeight: "500" }}>
          CIRCLES
        </Typography>

        <Box sx={{ width: "100%", mt: "16px" }}>
          <Typography sx={{ color: "#222", fontSize: "14px", mb: "30px", lineHeight: 1.7}}>
            Another special feature of <i>Davos</i> is its circles. As mentined before, when a polar projection is used for a shape, its format might resamble a circle. Some images contain no circles, while others are full of them.
          </Typography>
        </Box>

        <Box sx={{ width: "100%", height: "150px", mt: "20px", mb: "10px" }}>
          <Stack direction="row" justifyContent="space-evenly" sx={{ ml: "-50%" }}>
            <Image src={require("../../assets/Davos/no_circles.png")} height="130" width="130" />
            <Image src={require("../../assets/Davos/circles.png")} height="130" width="130" />
          </Stack>
        </Box>

        <Box sx={{ width: "100%", mt: "0px" }}>
          <Typography sx={{ color: "#222", fontSize: "14px", lineHeight: 1.7}}>
            The left image has no circles, and the right one has 46 of them. As you can see, their absence does not make the output inferior, there are many amazing results with no circles, but surely in some cases the circles give a final touch to the image.
          </Typography>
        </Box>
        </div>

        <div>
        <Typography sx={{ mt: "72px", color: "#222", fontSize: "22px", fontWeight: "500" }}>
          DISPOSITION
        </Typography>

        <Box sx={{ width: "100%", mt: "16px", mb: "30px" }}>
          <Typography sx={{ color: "#222", fontSize: "14px", lineHeight: 1.7}}>
            There are two possibilities for the positioning of the shapes. Just by looking at it, one can say whether the shapes are centered or spreaded:
          </Typography>
        </Box>

        <Box sx={{ width: "100%", height: "150px", mt: "20px", mb: "10px" }}>
          <Stack direction="row" justifyContent="space-evenly" sx={{ ml: "-50%" }}>
            <Image src={require("../../assets/Davos/centered.png")} height="130" width="130" />
            <Image src={require("../../assets/Davos/spreaded.png")} height="130" width="130" />
          </Stack>
        </Box>

        <Box sx={{ width: "100%", mt: "0px" }}>
          <Typography sx={{ color: "#222", fontSize: "14px", lineHeight: 1.7}}>
            Despite being only a difference of positioning, it gives the output a totally different appearance. Both are equally rare and beautiful. While the spreaded ones are very similar to tradicional abstract art, we like to think about the centered ones as supernovae or nuclei of atoms.
          </Typography>
        </Box>
        </div>

        <div>
        <Typography sx={{ mt: "72px", color: "#222", fontSize: "22px", fontWeight: "500" }}>
          PROJECT CONTRIBUTION
        </Typography>

        <Box sx={{ width: "100%", mt: "16px", mb: "40px" }}>
          <Typography sx={{ color: "#222", fontSize: "14px", lineHeight: 1.7}}>
            It is everyones responsability to do good deeds and contribute to a better world. This project is one way for us to contribute to many important causes around the world. That is why we will be donating at least 20% of our profit from all our collections to selected charity fundations, that will be listed on the <a href="/about" target="_blank" rel="noopener noreferrer">about page</a> very soon. We will focus our donations to fight hunger and to improve education on poor countries.
          </Typography>
        </Box>
        </div>

        <div>
        <Typography sx={{ mt: "72px", color: "#222", fontSize: "22px", fontWeight: "500" }}>
          INTERACTIVE WEB SIMULATION
        </Typography>

        <Box sx={{ width: "100%", mt: "16px", mb: "40px" }}>
          <Typography sx={{ color: "#222", fontSize: "14px", lineHeight: 1.7}}>
            For the sake of demonstration, we created a web simulation of <i>Davos</i>, where the user can interact with it. Of course it does not have the same quality of the original images, otherwise it would take a long time to render, but it's perfect for demonstrating how the creation process works. In this case, the shapes were pre created in lower resolution and only some color palettes are avaliable. The shapes are merged on top of the websites normal background, constantly loading new shapes and watching for a user click to insert another shape on the clicked spot. This demonstration was developed using p5.js library. Check it out by clicking in <a href="/" target="_blank" rel="noopener noreferrer">here</a>. Don't forget to try some clicks on it, have fun!
          </Typography>
        </Box>
        </div>

        </Box>
      </Mobile>

      <Footer />
      </div>
    );
  };
