import React, { useEffect, useState, useRef } from "react";
import Sketch from "react-p5";

import { Desktop, Tablet, Mobile } from '../../responsive';

import Header from "../Header/Header";

import Box from '@mui/material/Box';


export default function Home(props) {

  let palette = useRef();
  let calledPlay = useRef(false);

  const [w, setW] = useState(600);
  const [h, setH] = useState(600);

  const exceptionPercent = 20;


  useEffect(() => {
    palette.current = getPalette();
  }, []);


  async function setup(p5, width, height, y_pos, img_size) {
    let xyz = p5.createCanvas(width, height);
    let x = (p5.windowWidth - p5.width) / 2;
    let y = y_pos;
    xyz.position(x, y);

    p5.noLoop();

    setW(img_size);
    setH(img_size);

    //p5.background("black");

    var i = 0;
    while (true) {
      p5.redraw();
      await sleep(200);

      i++;
      if (i > 100) {
        changeImage(p5);
        i = 0;
      }
    }
  }

  function draw(p5) {
    p5.loadImage(getImgPath(), (img) => imageLoaded(img, p5));
  }

  function mousePressed(p5, event) {
    p5.loadImage(getImgPath(), (img) => imageLoaded(img, p5, event.layerX-(w/2), event.layerY-(h/2)));
    if (!calledPlay.current) {
      props.playFunction();
      calledPlay.current = true;
    }
  }


  function imageLoaded(img, p5, img_x=null, img_y=null) {
    const x = (p5.width - w)/2;
    const y = (p5.height - h)/2;

    const max_increment = 50
    const x_increment = Math.floor(Math.random() * (1.5*max_increment - (-1.5*max_increment) + 1)) + (-1.5*max_increment);
    const y_increment = Math.floor(Math.random() * (max_increment - (-max_increment) + 1)) + (-max_increment);


    if (img_x && img_y) {
      p5.image(img, img_x, img_y, w, h);
    }
    else {
      p5.image(img, x+x_increment, y+y_increment, w, h);
    }
  }


  function getImgPath() {
    const rand_int = Math.floor(Math.random() * 200) + 1;

    const isException = Math.floor(Math.random() * 101) <= exceptionPercent ? true : false;
    if (isException) {
      return require("../../assets/palettes/" + getPalette() + "/" + rand_int + ".png");
    }

    return require("../../assets/palettes/" + palette.current + "/" + rand_int + ".png");
  }

  function getPalette() {
    const palettes_list = ["agate", "amber", "amethyst", "ametrine", "beryl", "emerald", "fluorite", "hematite", "jasper", "onyx", "ruby", "sapphire", "sphalerite", "sugilite", "tanzanite", "variscite", "zircon"];

    const rand_int = Math.floor(Math.random() * palettes_list.length);

    return palettes_list[rand_int];
  }

  function changeImage(p5) {
    palette.current = getPalette();
    p5.clear();
  }

  function sleep(ms) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }

  return (
    <div>
    <Header />

    <Box>
      <Desktop>
        <Sketch setup={(p5) => setup(p5, p5.windowWidth, p5.windowHeight-180, 180, 500)} draw={draw} mousePressed={mousePressed} className="App" />
      </Desktop>
    
      <Tablet>
        <Sketch setup={(p5) => setup(p5, p5.windowWidth, p5.windowHeight-100, 100, 400)} draw={draw} mousePressed={mousePressed} className="App" />
      </Tablet>

      <Mobile>
        <Sketch setup={(p5) => setup(p5, p5.windowWidth, p5.windowHeight-70, 70, 300)} draw={draw} mousePressed={mousePressed} className="App" />
      </Mobile>

    </Box>

    </div>
  );
}

