import React from "react";
import { Desktop, Tablet, Mobile } from '../../responsive'
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import Typography from "@mui/material/Typography";
import Image from 'react-image-resizer';


export default function Header() {

  return (
    <Box>

    <Desktop>
      <Box sx={{py: "7%", px: "8%"}}>
        <Stack direction="row" justifyContent="space-between" alignItems="center">

          <Stack direction="row">

            <Box mr="120px" mt="-65px">
              <a href="/" style={{ textDecoration: 'none' }}>
                <Image src={require("../../assets/fiddler.png")} height="160" width="160" />
              </a>
            </Box>

              <a href="/" style={{ textDecoration: 'none' }}>
                <Typography sx={{ color: "#222", fontSize: "34px", fontWeight: "500", '&:hover': { color: '#484848' } }}>
                  THE FIDDLER
                </Typography>
              </a>
            
          </Stack>
          
          <Stack direction="row" sx={{pr: "60px"}}>
            <Box mr="22px">
              <a href="/davos" style={{ textDecoration: 'none' }}>
                <Typography sx={{ color: (window.location.href.includes("davos") ? "#222" : "#999"),
                  '&:hover': { color: '#007bcf' }, fontSize: "13px" }}>
                  DAVOS
                </Typography>
              </a>
            </Box>

            <a href="/about" style={{ textDecoration: 'none' }}>
              <Typography sx={{ color: (window.location.href.includes("about") ? "#222" : "#999"),
                '&:hover': { color: '#007bcf' }, fontSize: "13px" }}>
                ABOUT
              </Typography>
            </a>
          </Stack>

        </Stack>
      </Box>
    </Desktop>

    <Tablet>
      <Box sx={{py: "7%", px: "8%"}}>
        <Stack direction="row" justifyContent="space-between" alignItems="center">

          <Stack direction="row">

            <Box mr="80px" mt="-40px">
              <a href="/" style={{ textDecoration: 'none' }}>
                <Image src={require("../../assets/fiddler.png")} height="100" width="100" />
              </a>
            </Box>

              <a href="/" style={{ textDecoration: 'none' }}>
                <Typography sx={{ color: "#222", fontSize: "24px", fontWeight: "500", '&:hover': { color: '#484848' } }}>
                  THE FIDDLER
                </Typography>
              </a>
            
          </Stack>
          
          <Stack direction="row">
            <Box mr="20px">
              <a href="/davos" style={{ textDecoration: 'none' }}>
                <Typography sx={{ color: (window.location.href.includes("davos") ? "#222" : "#999"),
                  '&:hover': { color: '#3c52b2' }, fontSize: "13px" }}>
                  DAVOS
                </Typography>
              </a>
            </Box>

            <a href="/about" style={{ textDecoration: 'none' }}>
              <Typography sx={{ color: (window.location.href.includes("about") ? "#222" : "#999"),
                '&:hover': { color: '#3c52b2' }, fontSize: "13px" }}>
                ABOUT
              </Typography>
            </a>
          </Stack>

        </Stack>
      </Box>
    </Tablet>

    <Mobile>
      <Box sx={{py: "8%", px: "2%"}}>
        <Stack direction="row" justifyContent="space-between" alignItems="center">

          <Stack direction="row">

            <Box mr="55px" mt="-30px">
              <a href="/" style={{ textDecoration: 'none' }}>
                <Image src={require("../../assets/fiddler.png")} height="70" width="70" />
              </a>
            </Box>

              <a href="/" style={{ textDecoration: 'none' }}>
                <Typography sx={{ color: "#222", fontSize: "18px", fontWeight: "500", '&:hover': { color: '#484848' } }}>
                  THE FIDDLER
                </Typography>
              </a>
            
          </Stack>
          
          <Stack direction="row" sx={{pr: "10px"}}>
            <Box mr="12px">
              <a href="/davos" style={{ textDecoration: 'none' }}>
                <Typography sx={{ color: (window.location.href.includes("davos") ? "#222" : "#999"),
                  '&:hover': { color: '#007bcf' }, fontSize: "11px" }}>
                  DAVOS
                </Typography>
              </a>
            </Box>

            <a href="/about" style={{ textDecoration: 'none' }}>
              <Typography sx={{ color: (window.location.href.includes("about") ? "#222" : "#999"),
                '&:hover': { color: '#007bcf' }, fontSize: "11px" }}>
                ABOUT
              </Typography>
            </a>
          </Stack>

        </Stack>
      </Box>
    </Mobile>

    </Box>
  );
}
