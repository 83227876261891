import React from "react";
import { Route, Routes, BrowserRouter } from "react-router-dom";

import About from "./components/About/About";
import Davos from "./components/Davos/Davos";
import Home from "./components/Home/Home";
import Consciousness from "./components/Consciousness/Consciousness";
import BrStreetArtLive from "./components/BrStreetArt/BrStreetArt-live";
import BlindingLights from "./components/BlindingLights/BlindingLights";
import StarDust from "./components/StarDust/StarDust";

const MyRoutes = (props) => {
   return(
        <BrowserRouter>
            <Routes>
                <Route element={<Home playFunction={props.playFunction}/>}  path="/" exact />
                <Route element={<About />}  path="/about" />
                <Route element={<Davos />}  path="/davos" />

                <Route element={<Consciousness />}  path="/consciousness-live" />

                <Route element={<BrStreetArtLive />}  path="/noa-live" />
                <Route element={<BrStreetArtLive />}  path="/brazil-street-art" />

                <Route element={<StarDust />} path="star-dust" />

                <Route element={<BlindingLights />} path="blinding-lights" />
            </Routes>
        </BrowserRouter>
   )
}

export default MyRoutes;