import React from "react";
import { Desktop, Tablet, Mobile } from '../../responsive'
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import Typography from "@mui/material/Typography";
import InstagramIcon from '@mui/icons-material/Instagram';
import TwitterIcon from '@mui/icons-material/Twitter';
import OpenInBrowserIcon from '@mui/icons-material/OpenInBrowser';


export default function Footer() {

  return (
    <div>
    
    <Desktop>
      <Box sx={{py: "9%", px: "10%"}}>
        <Stack direction="row" justifyContent="space-between" alignItems="center">

          <Typography sx={{ color: "#464646", fontSize: "15px", mr: "10px" }}>
            ALL RIGHTS RESERVED. © THE FIDDLER
          </Typography>
          
          <Stack direction="row" sx={{pr: "10px"}}>
            <Box mr="22px">
              <a href="#" target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'none' }}>
                
                <InstagramIcon sx={{ color: "#bbbbbb", '&:hover': { color: '#6d6d6d' }, fontSize: "24px" }} />
                  
              </a>
            </Box>

            <Box mr="22px">
              <a href="#" target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'none' }}>
                
                <TwitterIcon sx={{ color: "#bbbbbb", '&:hover': { color: '#6d6d6d' }, fontSize: "24px" }} />
                  
              </a>
            </Box>

            <Box mr="22px">
              <a href="#" target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'none' }}>
                
                <OpenInBrowserIcon sx={{ color: "#bbbbbb", '&:hover': { color: '#6d6d6d' }, fontSize: "24px" }} />
                  
              </a>
            </Box>
          </Stack>

        </Stack>
      </Box>
    </Desktop>

    <Tablet>
      <Box sx={{py: "9%", px: "10%"}}>
        <Stack direction="row" justifyContent="space-between" alignItems="center">

          <Typography sx={{ color: "#464646", fontSize: "14px", mr: "10px" }}>
            ALL RIGHTS RESERVED. © THE FIDDLER
          </Typography>
          
          <Stack direction="row" sx={{pr: "0px"}}>
            <Box mr="18px">
              <a href="#" target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'none' }}>
                
                <InstagramIcon sx={{ color: "#bbbbbb", '&:hover': { color: '#6d6d6d' }, fontSize: "24px" }} />
                  
              </a>
            </Box>

            <Box mr="18px">
              <a href="#" target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'none' }}>
                
                <TwitterIcon sx={{ color: "#bbbbbb", '&:hover': { color: '#6d6d6d' }, fontSize: "24px" }} />
                  
              </a>
            </Box>

            <Box mr="22px">
              <a href="#" target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'none' }}>
                
                <OpenInBrowserIcon sx={{ color: "#bbbbbb", '&:hover': { color: '#6d6d6d' }, fontSize: "24px" }} />
                  
              </a>
            </Box>
          </Stack>

        </Stack>
      </Box>
    </Tablet>

    <Mobile>
      <Box sx={{py: "12%", px: "7%"}}>
        <Stack direction="row" justifyContent="space-between" alignItems="center">

          <Typography sx={{ color: "#464646", fontSize: "13px", mr: "10px" }}>
            ALL RIGHTS RESERVED. © THE FIDDLER
          </Typography>
          
          <Stack direction="row" >
            <Box mr="12px">
              <a href="#" target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'none' }}>
                
                <InstagramIcon sx={{ color: "#bbbbbb", '&:hover': { color: '#6d6d6d' }, fontSize: "20px" }} />
                  
              </a>
            </Box>

            <Box mr="12px">
              <a href="#" target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'none' }}>
                
                <TwitterIcon sx={{ color: "#bbbbbb", '&:hover': { color: '#6d6d6d' }, fontSize: "20px" }} />
                  
              </a>
            </Box>

            <Box mr="0px">
              <a href="#" target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'none' }}>
                
                <OpenInBrowserIcon sx={{ color: "#bbbbbb", '&:hover': { color: '#6d6d6d' }, fontSize: "20px" }} />
                  
              </a>
            </Box>
          </Stack>

        </Stack>
      </Box>
    </Mobile>

    </div>
  );
}
