import React, { useEffect, useState } from "react";
import Sketch from "react-p5";

import { Desktop, Tablet, Mobile } from '../../responsive';

import Box from '@mui/material/Box';


const genRanHex = size => [...Array(size)]
  .map(() => Math.floor(Math.random() * 16).toString(16)).join('');

const tokenData = {
    hash: "0x"+genRanHex(65),
    tokenId: "123000456"
};

class Random {
    constructor() {
      this.useA = false;
      let sfc32 = function (uint128Hex) {
        let a = parseInt(uint128Hex.substr(0, 8), 16);
        let b = parseInt(uint128Hex.substr(8, 8), 16);
        let c = parseInt(uint128Hex.substr(16, 8), 16);
        let d = parseInt(uint128Hex.substr(24, 8), 16);
        return function () {
          a |= 0; b |= 0; c |= 0; d |= 0;
          let t = (((a + b) | 0) + d) | 0;
          d = (d + 1) | 0;
          a = b ^ (b >>> 9);
          b = (c + (c << 3)) | 0;
          c = (c << 21) | (c >>> 11);
          c = (c + t) | 0;
          return (t >>> 0) / 4294967296;
        };
      };
      this.prngA = new sfc32(tokenData.hash.substr(2, 32));
      this.prngB = new sfc32(tokenData.hash.substr(34, 32));
      for (let i = 0; i < 1e6; i += 2) {
        this.prngA();
        this.prngB();
      }
    }
    random_dec() {
      this.useA = !this.useA;
      return this.useA ? this.prngA() : this.prngB();
    }
    random_num(a, b) {
      return a + (b - a) * this.random_dec();
    }
    random_int(a, b) {
      return Math.floor(this.random_num(a, b + 1));
    }
    random_bool(p) {
      return this.random_dec() < p;
    }
    random_choice(list) {
      return list[this.random_int(0, list.length - 1)];
    }
};


export default function BrStreetArtLive() {

  const [size, setSize] = useState();
  const [rand, setRand] = useState();
  const [opacity, setOpacity] = useState();
  const [skinType, setSkinType] = useState();
  const [wallType, setWallType] = useState();
  const [skinPalette, setSkinPalette] = useState();
  const [irisColor, setIrisColor] = useState();
  const [hairPalette, setHairPalette] = useState([""]);
  const [mouthPalette, setMouthPalette] = useState([]);
  const [wallPalette, setWallPalette] = useState();
  const [font, setFont] = useState();
  const [displayNum, setDisplayNum] = useState();
  const [initialEyePosition, setInitialEyePosition] = useState();
  const [eyesAttributes, setEyesAttributes] = useState();
  const [faceImg, setFaceImg] = useState();
  const [wallImg, setWallImg] = useState();
  const [wallOverlay, setWallOverlay] = useState();
  const [irisImg, setIrisImg] = useState();

  var DEFAULT_SIZE = 1000
  var WIDTH = window.innerWidth
  var HEIGHT = window.innerHeight
  var DIM = Math.min(WIDTH, HEIGHT)
  //var M = DIM / DEFAULT_SIZE


  useEffect(() => {
    const new_rand = new Random();
    const skinType_ = new_rand.random_choice(["default", "random", "random", "random", "pattern", "pattern"]);
    const wallType_ = new_rand.random_choice(["default", "default", "default", "pattern", "pattern"]);

    setRand(new_rand);
    setOpacity(new_rand.random_choice(["b0", "e0", "ff"]));
    setColors(new_rand, skinType_, wallType_);
    setSkinType(skinType_);
    setWallType(wallType_);
    setDisplayNum(new_rand.random_int(0, 100));
    setInitialEyePosition([new_rand.random_int(200, 700), new_rand.random_int(200, 700)]);
  }, []);


  function preload(p5) {
    setFont(p5.loadFont(require('./RapScript.otf')));
  };

  function setup(p5, size) {
    let xyz = p5.createCanvas(900, 900);
    xyz.position(200, 50);
    //xyz.mouseOut(() => {iris(p5, initialEyeX, initialEyeY)});

    let [wall_img, wall_overlay] = renderWall(p5)
    wall_img.resize(size, size);
    wall_overlay.resize(size, size);

    let face_img;
    let tempEyesAttributes;
    let noseAttributes_;
    let eyesImg_;
    let tempEyesAttributes_;
    let new_face;

    // Create Images
    if (displayNum < 60) { 
      face_img = [];
      for (let i=0; i<rand.random_int(2,6); i++) {
        let [skinPalette_, mouthPalette_, eyesColor_, hairPalette_] = setColors(rand, skinType, wallType);
        [new_face, tempEyesAttributes, noseAttributes_, eyesImg_, tempEyesAttributes_] = renderFace(p5, false, skinPalette_, mouthPalette_, eyesColor_, hairPalette_, noseAttributes_, eyesImg_, tempEyesAttributes_);
        new_face.resize(size, size);
        face_img.push(new_face);
      };
    }
    else {
      [face_img, tempEyesAttributes, noseAttributes_, eyesImg_, tempEyesAttributes_] = renderFace(p5);
      face_img.resize(size, size);
      let iris_img = iris(p5, initialEyePosition[0], initialEyePosition[1], tempEyesAttributes);
      iris_img.resize(size, size);
      setIrisImg(iris_img);
    };


    setSize(size);

    setWallImg(wall_img);
    setWallOverlay(wall_overlay);
    setFaceImg(face_img);
  };

  function draw(p5) {
    display(p5);
    p5.noLoop();
  };

  function mouseMoved(p5) {
    const eyeX = p5.mouseX;
    const eyeY = p5.mouseY;
    if (eyeX < 0 || eyeX > 900 || eyeY < 0 || eyeY > 900) { return; };
    
    if (displayNum >= 75) {
      let iris_img = iris(p5, eyeX, eyeY, eyesAttributes);
      iris_img.resize(size, size);
      setIrisImg(iris_img);
      draw(p5);
    };
  };


  function display(p5) {
    p5.image(wallImg, 0, 0);
  
    // Multiple Faces
    if (displayNum < 60) {
      const dividerColor = "#000000";
  
      for (let i=0; i<faceImg.length; i++) {
        let img = faceImg[i];
        const dividerWidth = rand.random_num(6, 8);
  
        let faceMask = p5.createGraphics(900, 900);
        faceMask.rect(i*(900/faceImg.length), 0, 900/faceImg.length, 900);
        faceMask.fill("#000000");
        let faceMaskImg = faceMask.get();
        img.mask(faceMaskImg);
  
        p5.image(img, 0, 0);
  
        if (i !== 0) {
          let divider = p5.createGraphics(900, 900);
          divider.noStroke();
          divider.fill(dividerColor);
          divider.rect(i*(900/faceImg.length) - (dividerWidth/2), 0, dividerWidth, 900);
          let dividerImg = divider.get();

          p5.image(dividerImg, 0, 0);
        };
      };
    }
    // Low opacity
    else if (displayNum < 75) {
      let diamondMask = p5.createGraphics(900, 900);
      const opacityList = ["#00000090", "#00000080", "#00000060", "#00000040"];
      randomShapesBg(p5, diamondMask, "", opacityList);
      let diamondMaskImg = diamondMask.get();
      faceImg.mask(diamondMaskImg);
  
      p5.image(faceImg, 0, 0);
      p5.image(irisImg, 0, 0);
    }
    // Default
    else {
      p5.image(faceImg, 0, 0);
      p5.image(irisImg, 0, 0);  
    };
  
    p5.image(wallOverlay, 0, 0);
  };


  function renderFace(p5, movement=true, skinPalette_=null, mouthPalette_=null, irisColor_=null, hairPalette_=null, noseAttributes=null, eyesImg=null, tempEyesAttributes=null) {
    if (skinPalette_ == null) { skinPalette_ = skinPalette };
    if (mouthPalette_ == null) { mouthPalette_ = mouthPalette };
    if (irisColor_ == null) { irisColor_ = irisColor };
    if (hairPalette_ == null) { hairPalette_ = hairPalette };

    let skinBg;
    if (skinType === "pattern") {
      skinBg = patternShapesBg
    }
    else {
      skinBg = randomShapesBg
    };

    let main_graph = p5.createGraphics(900, 900);
    skinBg(p5, main_graph, opacity, skinPalette_);
  
    //Eyebrows
    let eyebrowsMask = p5.createGraphics(900,900);
    eyebrows(p5, eyebrowsMask, "#000000");
    let eyebrowsMaskImg = eyebrowsMask.get();
    let eyebrowsHair = p5.createGraphics(900,900);
    hair(p5, eyebrowsHair, main_graph, false, true, hairPalette_);
    let eyebrowsHairImg = eyebrowsHair.get();
    eyebrowsHairImg.mask(eyebrowsMaskImg);
    main_graph.image(eyebrowsHairImg,0,0);
  
    //Eyes
    if (eyesImg == null || tempEyesAttributes == null) {
      let eyesCanvas = p5.createGraphics(900,900);
      tempEyesAttributes = eyes(p5, eyesCanvas);
      eyesImg = eyesCanvas.get();  
    };
    main_graph.image(eyesImg, 0, 0);

    //Iris
    if (!movement) {
      const irisImg = iris(p5, initialEyePosition[0], initialEyePosition[1], tempEyesAttributes, irisColor_);
      main_graph.image(irisImg, 0, 0);
    };
    
    //Nose
    let noseDx, noseDy;
    if (skinType !== "pattern") {
      let noseMask = p5.createGraphics(900,900);
      if (noseAttributes != null) {
        [noseDx, noseDy] = nose(p5, noseMask, "#00000040", true, noseAttributes[0], noseAttributes[1]);
      }
      else {
        [noseDx, noseDy] = nose(p5, noseMask, "#00000040", true);
      };
      noseAttributes = [noseDx, noseDy];

      let noseMaskImg = noseMask.get();
      let noseGraph = p5.createGraphics(900,900);
      randomShapesBg(p5, noseGraph, opacity, skinPalette_);
      let noseImg = noseGraph.get();
      noseImg.filter(p5.BLUR, 0.5)
      noseImg.mask(noseMaskImg);
      main_graph.image(noseImg, 0, 0);
      nose(p5, main_graph, "#00000040", false, noseDx, noseDy);
    }
    else {
      if (noseAttributes != null) {
        [noseDx, noseDy] = nose(p5, main_graph, "#00000060", false, noseAttributes[0], noseAttributes[1]);
      }
      else {
        [noseDx, noseDy] = nose(p5, main_graph, "#00000060", false);
      };
    };
  
    //Mouth
    let mouthGraph = p5.createGraphics(900,900);
    randomShapesBg(p5, mouthGraph, opacity, mouthPalette_);
    let mouthImg = mouthGraph.get()
    let mouthMask = p5.createGraphics(900,900);
    const mouthCoords = mouth(p5, mouthMask, [], true);
    let mouthMaskImg = mouthMask.get()
    mouthImg.mask(mouthMaskImg);
    mouthImg.filter(p5.BLUR, 1);
    main_graph.image(mouthImg, 0, 0);
    mouth(p5, main_graph, mouthCoords, false);
  
    //Face
    face(p5, main_graph, true);
  
    // Ears
    head(p5, main_graph, true);

    let mainImage = main_graph.get();
    mainImage.filter(p5.BLUR, 1.5);
  
    let mask_graphs = p5.createGraphics(900, 900)
    head(p5, mask_graphs);
    let mask_img = mask_graphs.get()
    mainImage.mask(mask_img);
  
    let faceMaskGraph = p5.createGraphics(900,900);
    face(p5, faceMaskGraph);
    let faceMask = faceMaskGraph.get();
    let faceImage = main_graph.get();
    faceImage.mask(faceMask);
  
    // Hair
    let hairGraph = p5.createGraphics(900,900);
    if (rand.random_bool(0.8)) {hair(p5, hairGraph, main_graph, false, false, hairPalette_)};
    let hairBackground = hairGraph.get();
  
    let final = p5.createGraphics(900,900);
    final.image(hairBackground,0,0);
    for (let i=0; i<10; i++) {
      final.image(mainImage,0,0);
      final.image(faceImage,0,0);
    };
    hair(p5, final, main_graph, true, false, hairPalette_);
  
    return [final.get(), tempEyesAttributes, noseAttributes, eyesImg, tempEyesAttributes];
  };
  
  function renderWall(p5) {
    let canvasBackground = p5.createGraphics(900, 900);
    let canvasOverlay = p5.createGraphics(900, 900);
  
    canvasBackground.noStroke();
    canvasOverlay.noStroke();
  
    const bgColor = rand.random_choice(["#c3c3c3", "#cbc1b5", "#979797", "#f4dabc"]);
    canvasBackground.background(bgColor);
    canvasOverlay.background(bgColor + "12");
      
    const bricksMaxLenght = rand.random_int(50, 120);

    for (let y=0; y<30; y++) {
      for (let x=-20; x<1000; x+=0) {
        const x_offset = rand.random_num(40, bricksMaxLenght);
        const brickHeight = 30;

        const brickCoords = [[x+rand.random_num(0,5), y*brickHeight +rand.random_num(0,3)], [x+rand.random_num(0,5), (y+0.8)*brickHeight +rand.random_num(0,3)], [x + x_offset +rand.random_num(0,3), (y+0.8)*brickHeight +rand.random_num(0,3)],[x + x_offset +rand.random_num(0,3), y*brickHeight +rand.random_num(0,3)]];
  
        const brickColor = rand.random_choice(wallPalette);
  
        for (let i=0; i<2; i++) {
          let canvas = [canvasBackground, canvasOverlay][i];
  
          // Bricks
          canvas.beginShape();
          canvas.vertex(brickCoords[0][0], brickCoords[0][1]);
          canvas.vertex(brickCoords[1][0], brickCoords[1][1]);
          canvas.vertex(brickCoords[2][0], brickCoords[2][1]);
          canvas.vertex(brickCoords[3][0], brickCoords[3][1]);
          if (i === 0) {
            canvas.fill(brickColor + rand.random_choice(["8", "9", "b", "d", "f"]) + "0");
          } else {
            canvas.fill("#F3DDC1" + rand.random_choice(["02", "08", "10"]));
          };
          canvas.endShape(p5.CLOSE);
  
          // Shadow
          canvas.beginShape();
          canvas.vertex(brickCoords[1][0], brickCoords[1][1]);
          canvas.vertex(brickCoords[1][0], brickCoords[1][1] + rand.random_num(1,3));
          canvas.vertex(brickCoords[2][0] + rand.random_num(1,3), brickCoords[2][1] + rand.random_num(1,3));
          canvas.vertex(brickCoords[3][0] + rand.random_num(1,3), brickCoords[3][1]);
          canvas.vertex(brickCoords[3][0], brickCoords[3][1]);
          canvas.vertex(brickCoords[2][0], brickCoords[2][1]);
          canvas.vertex(brickCoords[1][0], brickCoords[1][1]);
          if (i === 0) {
            canvas.fill("#000000" + rand.random_choice(["8", "9", "b"]) + "0");
          } else {
            canvas.fill("#000000" + rand.random_choice(["10", "15", "20"]));
          };
          canvas.endShape(p5.CLOSE);
        };
  
        x += x_offset + rand.random_num(5,10)
  
        if (rand.random_bool(0.01)) {x += x_offset + rand.random_num(5,10)};
      };
    };

    if (wallType === "pattern") {
      patternShapesBg(p5, canvasBackground, "", wallPalette, "wall");
      /*if (rand.random_bool(0.3)) {
        canvasOverlay = createGraphics(900, 900);
      };*/
    };
  
    adornWall(p5, canvasBackground);
  
    return [canvasBackground.get(), canvasOverlay.get()];
  };
  
  
  function adornWall(p5, originalCanvas) {
  
    function graffiti() {
      const shadowColor = "#000000";
  
      const colorsList = [["#FFFFFF", "#293462", "#F24C4C", "#EC9B3B", "#F7D716"], ["#36AE7C", "#2E0249", "#570A57", "#A91079", "#F806CC"], ["#FFFFFF", "#009C3B", "#FFDF00", "#002776"]];
      const colorIndex = rand.random_int(0, colorsList.length - 1);
      let wordsList = ["amortesalva", "amazonia", "bola", "neymar", "pelé", "amor", "paz", "familia", "rio", "arte", "carnaval", "festa", "sao paulo", "brasil", "futebol", "cachaça", "caipirinha", "feira", "favela", "nordeste", "bahia", "iguaçu", "natal", "salvador", "mano", "quebrada", "tupi guarani", "açai"];
  
      const graffitiNum = rand.random_int(30, 50);
      for (let i=0; i< graffitiNum; i++) {
        let canvas = p5.createGraphics(900, 900);
        canvas.noStroke();
        canvas.textFont(font);
        canvas.textAlign(p5.TOP, p5.LEFT);
  
        const rotation = rand.random_num(-0.2, 0.2) * p5.PI;
        canvas.rotate(rotation);
  
        const fontSize = rand.random_int(30, 60);
        const word = rand.random_choice(wordsList.splice(rand.random_int(0, wordsList.length-1), 1));
        const textColor = rand.random_bool(0.2) ? "#000000" : rand.random_choice(colorsList[colorIndex]);
  
        canvas.textSize(textColor.includes("#000000") ? fontSize-8 : fontSize);
  
        canvas.fill(shadowColor + "e0");
        canvas.text(word, 100, 300);
        canvas.fill(shadowColor + "e0");
  
        if (!textColor.includes("#000000") || rand.random_bool(0.2)) {
          canvas.fill(textColor + "e0");
          canvas.text(word, 100+3, 300-3);
        }
  
        let textImage = canvas.get();
        textImage.filter(p5.BLUR, 0.8);
  
        let x = rand.random_bool(0.5) ? rand.random_num(-100, -20) : rand.random_num(600, 680);
        let y = rand.random_num(-300, 700);
  
        originalCanvas.image(textImage, x, y);
      };
    };
  
    if (rand.random_bool(0.7)) {
      graffiti();
    };
  };
  
  
  function randomShapesBg(p5, canvas, opacity="", palette=[]) {
      const curvesAllowed = rand.random_bool(0.5);
      if (palette.length === 0) {palette = skinPalette};

      function getNearPoint(points_list, center, radius) {
        let point = points_list[rand.random_int(0, points_list.length-1)];
      
        if (p5.dist(point[0], point[1], center[0], center[1]) > radius) {
          return getNearPoint(points_list, center, radius);
        };
       
        return point;  
      };

      const bgColor = rand.random_choice(palette);
      canvas.noStroke();
      canvas.background(bgColor);
  
      let points_list = []
      for (let i=0; i < rand.random_int(120,150); i++) {
        points_list.push([rand.random_num(0, canvas.width), rand.random_num(0, canvas.height)])
      };
      
      for (let i=0; i < rand.random_int(100,120); i++) {
          canvas.beginShape();
          let last_point = points_list[rand.random_int(0, points_list.length-1)];
  
          for (let j=0; j < rand.random_int(4,8); j++) {
              let point = getNearPoint(points_list, last_point, rand.random_num(200,300))
              if (rand.random_bool(0.5)) {last_point = point}; 
  
              if (!curvesAllowed) {
                canvas.vertex(point[0], point[1]);
              } else {
                canvas.curveVertex(point[0], point[1]);
              };
          };
          canvas.fill(rand.random_choice(palette) + opacity);
          canvas.endShape(p5.CLOSE);
      };
  };

  function patternShapesBg(p5, canvas, opacity="", palette=[], type="face") {
    const l = rand.random_int(50, 80);
    const patternType = rand.random_choice(["vertical", "horizontal", "diamond", "random"]);
    const randPercent = rand.random_choice([0, 0.1, 0.2, 0.3]);
  
    const y_inicial = rand.random_num(-4, -2);
    const y_final = canvas.height/l + 3;
  
    const x_inicial = rand.random_num(-4, -2);
    const x_final = canvas.width/l + 3;
  
    let colorList = [];
    let oldColorList = [];

    let i = rand.random_choice([-1, 1]);
    let j;
    for (let y=y_inicial; y<y_final; y++) {
      i = -i;
      j = i;
  
      // Colors loop
      oldColorList = [...colorList];
      colorList = [];
      let colorIndex = 0;
      for (let x=x_inicial; x<x_final*2+30; x++) {
        if (patternType === "vertical") {
          if (oldColorList.length === 0) {
            colorList.push(rand.random_choice(palette));
          }
          else if (i === j) {
            if (colorIndex%4===0) {
              if (j===1) {
                colorList.push(oldColorList[colorIndex]);
              }
              else {
                colorList.push(rand.random_choice(palette));
              };
            }
            else {
              if (j===1) {
                colorList.push(rand.random_choice(palette));
              }
              else {
                colorList.push(oldColorList[colorIndex]);
              };
            };
          }
          else {
            if ((colorIndex+1)%4===0) {
              if (j===1) {
                colorList.push(rand.random_choice(palette));
              }
              else {
                colorList.push(oldColorList[colorIndex]);
              };
            }
            else {
              if (j===1) {
                colorList.push(oldColorList[colorIndex]);
              }
              else {
                colorList.push(rand.random_choice(palette));
              };
            };
          };
        }
        else if (patternType === "horizontal") {
          if (i === j) {
            colorList.push(rand.random_choice(palette));
          }
          else {
            if (colorList.length > 3) {
              colorList.push(colorList[colorList.length-3]);
            } 
            else {
              colorList.push(rand.random_choice(palette));
            };
          };
        }
        else if (patternType === "diamond") {
          if (oldColorList.length === 0) {
            if (i === j) {
              colorList.push(rand.random_choice(palette));
            }
            else {
              if (colorList.length > 3) {
                colorList.push(colorList[colorList.length-3]);
              } 
              else {
                colorList.push(rand.random_choice(palette));
              };
            };
          }
          else if (i === j) {
            if (colorIndex%4===0) {
              if (j===1) {
                colorList.push(oldColorList[colorIndex]);
              }
              else {
                colorList.push(rand.random_choice(palette));
              };
            }
            else {
              if (j===1) {
                colorList.push(rand.random_choice(palette));
              }
              else {
                colorList.push(oldColorList[colorIndex]);
              };
            };
          }
          else {
            if (colorList.length < 3) {
              colorList.push(rand.random_choice(palette));
            }
            else {
              colorList.push(colorList[colorList.length-3]);
            }
          };
        }
        else {
          colorList.push(rand.random_choice(palette));
        };
  
        colorIndex++;
        j = -j;
      };
  
      // Shapes loop
      j = i;
      colorIndex = 0;
      for (let x=x_inicial; x<x_final; x++) {
        const color1 = rand.random_bool(randPercent) ? rand.random_choice(palette) : colorList[colorIndex];
        canvas.beginShape();
        canvas.stroke(color1);
        canvas.vertex(x*l, (y - (0.5 + 0.5*j))*l);      
        canvas.vertex((x+1)*l, (y - (0.5 + 0.5*j))*l);
        canvas.vertex((x+1)*l, (y - (0.5 - 0.5*j))*l);
        canvas.fill(color1);
        canvas.endShape(p5.CLOSE);
        colorIndex++;
  
        const color2 = rand.random_bool(randPercent) ? rand.random_choice(palette) : colorList[colorIndex];
        canvas.beginShape();
        canvas.stroke(color2);
        canvas.vertex(x*l, (y - (0.5 + 0.5*j))*l);
        canvas.vertex(x*l, (y - (0.5 - 0.5*j))*l);
        canvas.vertex((x+1)*l, (y - (0.5 - 0.5*j))*l);
        canvas.fill(color2);
        canvas.endShape(p5.CLOSE);
        colorIndex++;
  
        j = -j;
      };
    };
  
    canvas.noStroke();
  
    if (rand.random_bool(0.1) && type==="wall") {
      const y_offset = rand.random_num(150, 200);
      for (let x=x_inicial; x<x_final*l; x+=0) {
        const x_offset = rand.random_num(15, 30);
        canvas.rect(x, 0, x_offset, y_offset);
        canvas.fill(rand.random_choice(palette));
  
        canvas.rect(x, canvas.height-y_offset, x_offset, y_offset);
        canvas.fill(rand.random_choice(palette));
        x += x_offset;
      };
    };
  
    if (rand.random_bool(0.1)) {
      const y_offset = rand.random_choice([0, 100, 200, 300, 400]);
      const dy = rand.random_int(40, 60);
  
      canvas.rect(0, 250+y_offset, canvas.width, dy);
      canvas.fill(rand.random_choice(palette));
      canvas.rect(0, 250+dy+y_offset, canvas.width, dy);
      canvas.fill(rand.random_choice(palette));
      canvas.rect(0, 250+(2*dy)+y_offset, canvas.width, dy);
      canvas.fill(rand.random_choice(palette));
    };
  };

  
  function setColors(rand, skinType, wallType) {

    // Skin
    function randShapesColors() {
      const paletteNum = rand.random_int(0,100);
      
      // Brazil - Carnaval
      if (paletteNum < 5) {
        return [false, ["#F8E00A", "#F1611E", "#F79336", "#F07099", "#D51D23", "#82293C", "#A9376E", "#F59580", "#532973", "#342B7C", "#082562", "#009DE0", "#00A489", "#004726", "#2C9B3B", "#63B74A", "#4A8031", "#421101", "#684130", "#8F3738", "#495256", "#00151F", "#9E9FA3"]];  
      }
      // Black
      else if (paletteNum < 15) {
        return [true, ["#DADCE7", "#B5B9CF", "#9096B6", "#777EA6", "#616894", "#51567B", "#404463", "#282B3E", "#181A25", "#08090C"]];
      }
      // White
      else if (paletteNum < 25) {
        return [false, ["#F1DBD3", "#E6C2C9", "#B0ABC9", "#95A0C9", "#7A94C8", "#8E9ECB", "#B6B2D1", "#CABCD4", "#E3D8D9", "#E3D8D9", "#E3AEB7"]];
      }
      // Indigenous
      else if (paletteNum < 60) {
        return [false, ["#001219", "#005f73", "#0a9396", "#94d2bd", "#e9d8a6", "#ee9b00", "#ca6702", "#bb3e03", "#ae2012", "#9b2226" ,     "#3d5a80", "#98c1d9", "#e0fbfc", "#ee6c4d", "#293241"]];
      }
      // Brown
      else {
        return [true, ["#1F1718", "#2C181A", "#3E2323", "#5B2820", "#BB7C9C", "#C79132", "#6E4D1E", "#24431A", "#EED0D0", "#D98275", "#713C45", "#C25568", "#4B978F", "#423C33", "#41431C", "#E493A6", "#B4BACE", "#62923F"]];
      };
    };

    function defaultSkinColors() {
      const paletteNum = rand.random_int(0,100);
      // Pink
      if (paletteNum < 15) {
        return [false, ["#E6C2C9", "#cdb3b8", "#ebb2bd", "#f0d1d7"]];
      }
      // Orange
      else if (paletteNum < 25) {
        return [false, ["#E6BA9F", "#C7845F", "#E6BCA3", "#D5A17F", "#B36B43", "#D89777", "#DCAF93", "#E8C6B6"]];
      }
      // Beige
      else if (paletteNum < 40) {
        return [false, ["#D89E8D", "#DEAC9F", "#BD8166", "#BA7962", "#DEB29C"]];
      }
      // Sand
      else if (paletteNum < 60) {
        return [true, ["#A7664F", "#C78767", "#C08265", "#A0674C", "#784738", "#C88F78", "#A46345", "#8A4838", "#703E29"]];
      }
      // Brown
      else if (paletteNum < 80) {
        return [true, ["#6E3E2F", "#7E4133", "#6F372A", "#683A2C", "#70412F", "#4B281C", "#54301F", "#603827", "#542F22", "#A05D41"]];
      }
      else {
        return [true, ["#492F22", "#533423", "#503D32", "#372A26", "#4C3128", "#3B2B26", "#7B5D4C", "#48332F", "#5C3E2E"]]
      }
    };

    // Eyes
    function eyesColor() {
      return rand.random_choice(["#5E8B61", "#484A25", "#212113", "#472B02", "#594028", "#6B4514", "#7E3A18", "#93592F", "#46311A", "#5E5341", "#594D10", "#818D76", "#A8A77C", "#7B745E", "#2E464B", "#7AA7B2", "#334A4E", "#3C5249", "#595F5D", "#3C5159", "#4773B2", "#75889B", "#374A6D", "#347992", "#394968", "#6F88AD", "#0000FF", "#1B0C06", "#3F200B", "#8D644D", "#091753", "#99B7C6", "#f79447"]) + rand.random_choice(["6", "7", "8", "a", "c", "e"]) + "0";
    };

    // Hair
    function defaultHairColor(melanine) {
      const paletteNum = rand.random_int(0,100);

      // Ginger
      if (paletteNum < 15 && !melanine) {
        return ["#70130C", "#BD7C4C", "#C35715", "#FBAA61", "#9E5722", "#903009", "#832500", "#693614", "#8C552E", "#CB6423"];
      }
      // Blonde
      else if (paletteNum < 45 && !melanine) {
        return ["#CEB292", "#B19476", "#F8E6CD", "#D9B996", "#FAE8D4", "#C5A489", "#CBB499", "#C1A275", "#E4CDAE", "#D7C1A7"];
      }
      // Brunete
      else if (paletteNum < 70) {
        return ["#383234", "#A78377", "#483D39", "#473D3C", "#B0836F", "#9F7A71", "#6E5452", "#38261B", "#493B3A", "#64483C"];
      }
      // Dark
      else {
        return ["#000000", "#191617", "#272626", "#171616", "#0a0809", "#060404", "#191818", "#0f0d0d", "#292828", "#373737"];
      }
    };

    // Mouth
    function defaultMouthColor() {
      const mouthPalettes = [["#E38D8C", "#A9665B", "#D2847C", "#E49995", "#D0726C"],
                      ["#D1664B", "#982816", "#B93E25", "#DF907E", "#B13920"]];

      return rand.random_choice(mouthPalettes);
    };

    // Wall
    function defaultBricksColors() {
      return rand.random_choice([["#67382D", "#964332", "#703529", "#6F483A", "#9A5C44", "#8E381E"], 
                                ["#BC4C1D", "#E2B17D", "#EBBF9D", "#96776F", "#95745A", "#979455"],
                                ["#F3DDC1", "#E3C7AA", "#AC886C", "#C6A07B", "#BCA27C", "#CAAB89"],
                                ["#B3654E", "#DE7856", "#AC886C", "#E2B17D", "#D3A18A", "#63443B"],
                                ["#3b4040", "#626565", "#34302f", "#2b1d18", "#262625", "#1d1a18"]]);
    };
    
    // Pattern
    function patternShapesColors() {
      const paletteNum = rand.random_int(0,100);
  
      if (paletteNum < 25) {
        return ["#08788B", "#CA3C18", "#A60B1E", "#27132F", "#144259", "#46399B", "#3D3D97", "#701127", "#A52867", "#77323F", "#9B6247", "#957199", "#A79683", "#2C604B"];
      }
      else if (paletteNum < 50) {
        return ["#D97841", "#DFBD1D", "#0B90B9", "#B52679", "#87C045", "#E0401D", "#7ACDB6", "#3C2166", "#D44D09", "#DCB267", "#3F4174", "#B3175A", "#D1B948"];
      }
      else if (paletteNum < 75) {
        return ["#F35EC8", "#EC3BAD", "#FE566B", "#DC9BDD", "#F3ADC5", "#9082E1", "#DF1E1A", "#9D3661", "#651C1F", "#F2A532", "#EC6D6B", "#F95E18", "#972270", "#8E604B"];
      }
      else {
        return ["#0A2E64", "#6E3A15", "#C5C4C7", "#822C50", "#4C8AAE", "#AF4967", "#B28601", "#8F4A00", "#4D0307"];
      };
    };


    // Colors
    // - Face
    let melanine;
    let skinPalette_;
    let hairPalette_;
    let mouthPalette_;

    if (skinType==="default") {
      [melanine, skinPalette_] = defaultSkinColors();
      hairPalette_ = defaultHairColor(melanine);
      mouthPalette_ = defaultMouthColor();
    }
    else if (skinType==="random") {
      [melanine, skinPalette_] = randShapesColors();
      mouthPalette_ = rand.random_bool(0.2) ? defaultMouthColor() : [];
      hairPalette_ = rand.random_bool(0.2) ? defaultHairColor(melanine) : [""];
    }
    else {
      [melanine, skinPalette_] = randShapesColors();
      mouthPalette_ = defaultMouthColor();
      hairPalette_ = rand.random_bool(0.4) ? defaultHairColor(melanine) : [""];
    };

    const eyesColor_ = eyesColor();

    setSkinPalette(skinPalette_);
    setIrisColor(eyesColor_);
    setHairPalette(hairPalette_);
    setMouthPalette(mouthPalette_);  

      
    let wallPalette_;

    // - Wall
    if (wallType==="default") {
      if (rand.random_bool(0.6)) {wallPalette_ = defaultBricksColors()} else {wallPalette_ = patternShapesColors()};
    }
    else {
      wallPalette_ = patternShapesColors();
    };
    setWallPalette(wallPalette_);

    return [skinPalette_, mouthPalette_, eyesColor_, hairPalette_];
  };
  
  
  // Face attributtes
  function eyebrows(p5, canvas, color) {
    canvas.noStroke();
    let a = canvas.width/2 + 48;
    let b = 268;
    for (let i=1; i>-2; i-=2) {
      const vList = [[0,0], [20*i,-30], [120*i,-44], [176*i,-18], [146*i,-18]];
      canvas.beginShape();
      for (let i in vList) {
        if (rand.random_bool(0.1)) {
          canvas.curveVertex(a+rand.random_int(-8,8)+vList[i][0], b+rand.random_int(-5,5)+vList[i][1])
        } else {
          canvas.vertex(a+rand.random_int(-8,8)+vList[i][0], b+rand.random_int(-5,5)+vList[i][1])
        };
      };
      canvas.fill(color);
      canvas.endShape(p5.CLOSE);
      a -= rand.random_int(85, 90);    
    };
  };
  
  function eyes(p5, canvas) {
    canvas.noStroke();
    
    let a = canvas.width/2 + rand.random_int(70, 75);
    let b = canvas.height/3 + rand.random_int(2, 8);
  
    const eyeHeight = rand.random_num(1.55, 1.86);
    const eyeYstart = rand.random_num(0.85, 1.25);
    const eyeWidth = rand.random_num(1, 1.2);
  
    let sclera_canvas_list = [];
    let eyeLeft = [];
    let eyeDx = [];
    let eyeTop = [];
    let eyeDy = [];

  
    for (let j=1; j>-2; j-=2) {
        // Sclera
  
        let sclera_canvas = p5.createGraphics(canvas.width, canvas.height);
        sclera_canvas.noStroke();
        sclera_canvas.beginShape();
        
        let i = eyeYstart;
        for (let k=0; k<2; k++) {  
          sclera_canvas.vertex(a, b);
          sclera_canvas.vertex(a+8*j*eyeWidth, b - 10*i);
          sclera_canvas.vertex(a+18*j*eyeWidth, b - 17*i);
          sclera_canvas.vertex(a+28*j*eyeWidth, b - 22*i);
          sclera_canvas.vertex(a+38*j*eyeWidth, b - 24*i);
          sclera_canvas.vertex(a+48*j*eyeWidth, b - 26*i);
          sclera_canvas.vertex(a+58*j*eyeWidth, b - 24*i);
          sclera_canvas.vertex(a+68*j*eyeWidth, b - 21*i);
          sclera_canvas.vertex(a+78*j*eyeWidth, b - 14*i);
          sclera_canvas.vertex(a+88*j*eyeWidth, b - 6*i);
          sclera_canvas.vertex(a+92*j*eyeWidth, b -3);
          sclera_canvas.vertex(a, b);

          i-=eyeHeight;
        };
  
        sclera_canvas.fill("#f6f6f6");
        sclera_canvas.endShape(p5.CLOSE);
  
        const sclera_img = sclera_canvas.get();
        canvas.image(sclera_img, 0, 0);
  
        sclera_canvas_list.push(sclera_img);

        // Below eye
        canvas.beginShape();
        canvas.vertex(a-2*j*eyeWidth, b + 7*eyeHeight);
        canvas.curveVertex(a+28*j*eyeWidth, b + 12*eyeHeight);
        canvas.curveVertex(a+50*j*eyeWidth, b + 15*eyeHeight);
        canvas.curveVertex(a+70*j*eyeWidth, b + 13*eyeHeight);
        canvas.vertex(a+94*j*eyeWidth, b + 6*eyeHeight);
        canvas.curveVertex(a+70*j*eyeWidth, b + 16*eyeHeight);
        canvas.curveVertex(a+46*j*eyeWidth, b + 18*eyeHeight);
        canvas.curveVertex(a+28*j*eyeWidth, b + 15*eyeHeight);
        canvas.fill("#00000025");
        canvas.endShape(p5.CLOSE);
  
        // Iris attributes (resumes in other function) 
        eyeLeft.push(a);
        eyeDx.push(92*j*eyeWidth);
        eyeTop.push(b - 26*eyeYstart);
        eyeDy.push(26*eyeHeight);
  
        // Eyelashes
        canvas.fill("#ffffff00");
        canvas.strokeWeight(0.5);
        let y = b - 18*eyeYstart; //Upper
        for (let i = a+6*eyeWidth*j; p5.abs(i-a) < 105*eyeWidth; i+=j) {
          canvas.stroke(canvas.get(rand.random_num((canvas.width/2)*0.25, (canvas.width/2)*0.5), rand.random_num((canvas.height/2)*0.25, (canvas.height/2)*0.5)));
  
          p5.abs(i-a)< 43 ? y-=0.3 : p5.abs(i-a)< 68 ? y+=0 : y+=0.4;
          let h = p5.abs(i-a)< 78 ? 3 : 3+(i-48)*0.01;
  
          canvas.curve(i, y, i+rand.random_int(-5,5), y+5+rand.random_int(-5,5), i+rand.random_int(-15,15), y-h+rand.random_int(-2,2), i+rand.random_int(-10,10), y+5+rand.random_int(-5,5));
        };
  
        canvas.strokeWeight(0.3);
        y = b - 23*(eyeYstart-eyeHeight); //Lower
        for (let i = a+28*eyeWidth*j; p5.abs(i-a) < 103*eyeWidth; i+=2*j) {
          canvas.stroke(canvas.get(rand.random_num((canvas.width/2)*0.25, (canvas.width/2)*0.5), rand.random_num((canvas.height/2)*0.25, (canvas.height/2)*0.5)));
  
          p5.abs(i-a) < 43 ? y+=0.4 : p5.abs(i-a)< 68 ? y+=0 : y-=0.95;
  
          canvas.curve(i, y, i+rand.random_int(-5,5), y+rand.random_int(-1,4), i+rand.random_int(-15,15), y+rand.random_int(-2,2), i+rand.random_int(-10,10), y+rand.random_int(-1,3));
        };
        canvas.noStroke();
  
        // Shadow
        canvas.beginShape();
        y = b - 18*eyeYstart;
        i = a+8*j;
  
        canvas.vertex(i, y+6);
        canvas.vertex(i+25*j, y-12);
        canvas.vertex(i+45*j, y-12);
        canvas.vertex(i+75*j, y-5);
        canvas.vertex(i+90*j, y+16);
        canvas.curveVertex(i+45*j, y+rand.random_num(-3, 3));
  
        canvas.fill("#00000020");   
        canvas.endShape(p5.CLOSE);
  
        a = canvas.width - a;
    };
  
    setEyesAttributes([sclera_canvas_list, eyeLeft, eyeDx, eyeTop, eyeDy]);

    return [sclera_canvas_list, eyeLeft, eyeDx, eyeTop, eyeDy];
  };
  
  function iris(p5, eyeX, eyeY, tempAttributes=null, irisColor_=null) {
    if (irisColor_ == null) { irisColor_ = irisColor };

    let sclera_mask_list, eyeLeft, eyeDx, eyeTop, eyeDy;

    if (tempAttributes != null) {
      [sclera_mask_list, eyeLeft, eyeDx, eyeTop, eyeDy] = tempAttributes;
    }
    else {
      [sclera_mask_list, eyeLeft, eyeDx, eyeTop, eyeDy] = eyesAttributes;
    };


    let final_canvas = p5.createGraphics(sclera_mask_list[0].width, sclera_mask_list[0].height);

    let iris_centerX = (eyeLeft[0] +50) + ((eyeX-final_canvas.width/2)/(final_canvas.width/2))*eyeDx[0]*0.2;
    const iris_centerY = (eyeTop[0]+eyeDy[0]/2) + ((eyeY-final_canvas.height/3)/(final_canvas.height/2))*9;

    for (let i=0; i<2; i++) {
      let canvas = p5.createGraphics(final_canvas.width, final_canvas.height);
      canvas.noStroke();
  
      const j = i === 0 ? 1 : -1;
      const eyeWidth = eyeDx[i]/(92*j);
    
      // Iris
      canvas.beginShape();
      canvas.fill(irisColor_);
      canvas.circle(iris_centerX, iris_centerY, (42*eyeWidth) - 0.1*p5.abs(iris_centerX - eyeLeft[i] - j*50));
      canvas.endShape(p5.CLOSE);
  
      // Pupil
      canvas.beginShape();
      canvas.fill("#090909");
      canvas.circle(iris_centerX, iris_centerY, (23*eyeWidth) - 0.12*p5.abs(iris_centerX - eyeLeft[i] - j*50));
  
      // Reflex
      const reflexY = canvas.height/3.02 - ((eyeY-canvas.height/3)/(canvas.height/2))*7;
      const reflexX = (eyeLeft[i]+j*50) + j*(Math.cos( (reflexY-canvas.height/3.02) / 35*eyeWidth ))*((eyeX-canvas.width/2)/(canvas.width/2))*eyeDx[i]*0.4;
  
      canvas.fill("#ffffff" + String(40 + Math.round( p5.abs(40*Math.cos((reflexY-canvas.height/3)) ))));
      canvas.ellipse(reflexX, reflexY, 13*eyeWidth, 12*(eyeWidth**2));
      canvas.endShape(p5.CLOSE);
  
      let iris_img = canvas.get();
      iris_img.mask(sclera_mask_list[i]);
      final_canvas.image(iris_img, 0, 0);

      iris_centerX = iris_centerX - (eyeLeft[0] - eyeLeft[1]) - 100;
    };
  
    return final_canvas.get();
  };
  
  function nose(p5, canvas, noseContour, contour=false, deltaX=null, deltaY=null) {
    let a = canvas.width/2 + rand.random_num(19,21);
    let dx = (deltaX != null ? deltaX : rand.random_num(-7,7));
    let dy = (deltaY != null ? deltaY : rand.random_num(-7,7));
    
    for (let i=1; i>-2; i-=2) {
      if (contour) {
        canvas.beginShape();
        canvas.vertex(a-20*i, 300 +dy);
        canvas.vertex(a+(10+dx)*i, 300 +dy);
        canvas.vertex(a+(54+dx)*i, 456 +dy);
        canvas.vertex(a+(49+dx)*i, 480 +dy);
        canvas.vertex(a+(36+dx)*i, 490 +dy);
        canvas.vertex(a+(26+dx)*i, 494 +dy);
        canvas.vertex(a+(20+dx)*i, 496 +dy);
        canvas.vertex(a+(12+dx)*i, 497 +dy);
        canvas.vertex(a-20*i, 498 +dy);
        canvas.vertex(a-20*i, 300 +dy);
        canvas.fill("#000000");
        canvas.endShape(p5.CLOSE);
      };
  
      if (!contour) {
        canvas.beginShape();
        canvas.vertex(a, 491 +dy);
        canvas.vertex(a+30*i, 486 +dy);
        canvas.curveVertex(a+18*i, 478 +dy);
        canvas.fill("#000000e0");
        canvas.endShape(p5.CLOSE);
        
        canvas.beginShape();
        canvas.vertex(a+27*i, 490 +dy);
        canvas.curveVertex(a+16*i, 495 +dy);
        canvas.vertex(a+16*i, 498 +dy);
        canvas.fill("#00000030");
        canvas.endShape(p5.CLOSE);
  
        canvas.beginShape();
        canvas.vertex(a+(10+dx)*i, 300 +dy);
        canvas.curveVertex(a+(54+dx)*i, 456 +dy);
        canvas.vertex(a+(26+dx)*i, 492 +dy);
        canvas.curveVertex(a+(56+dx)*i, 462 +dy);
        canvas.fill(noseContour);
        canvas.endShape(p5.CLOSE);
  
        canvas.beginShape();
        canvas.vertex(a+(10+dx)*i, 300 +dy);
        canvas.curveVertex(a+(54+dx)*i, 456 +dy);
        canvas.vertex(a+(26+dx)*i, 480 +dy);
        canvas.fill("#00000020");
        canvas.endShape(p5.CLOSE);
      };
  
      a -= 32;
    };

    if (!contour) {
      let c = rand.random_num(-2,2);
      let d = rand.random_num(-2,2);
  
      canvas.beginShape();
      canvas.vertex(canvas.width/2 - 12+c, 493+(d+dy));
      canvas.curveVertex(canvas.width/2 + 3+c, 496+(d+dy));
      canvas.vertex(canvas.width/2 + 18+c, 493+(d+dy));
      canvas.curveVertex(canvas.width/2 + 3+c, 498+(d+dy));
      canvas.fill("#20202060");
      canvas.endShape(p5.CLOSE);
    };
  
    return [dx, dy];
  };
  
  function mouth(p5, canvas, mouthCoords, area) {
    if (mouthCoords.length === 0) {
      mouthCoords = [canvas.width/2 -111 + rand.random_num(0, 4), rand.random_num(562,566), canvas.width/2 + 102 + rand.random_num(0, 4), rand.random_num(562,566)];
    };
  
    function mouthShape(canvas, color, u=rand.random_num(0.65,1.1), b=rand.random_num(0.65,0.9)) {
      canvas.beginShape();
      canvas.vertex(mouthCoords[0], mouthCoords[1]);
      canvas.vertex(canvas.width/2 + rand.random_num(-66,-64), mouthCoords[1] - rand.random_num(12,14)*u + (u===0?rand.random_num(-5,0):0));
      canvas.vertex(canvas.width/2 + rand.random_num(-25,-18), mouthCoords[1] - rand.random_num(24,28)*u + (u===0?rand.random_num(-5,0):0));
      canvas.vertex(canvas.width/2 +  rand.random_num(2,8), mouthCoords[1] - rand.random_num(14,16)*u + (u===0?rand.random_num(-5,0):0));
      canvas.vertex(canvas.width/2 + rand.random_num(25,32), mouthCoords[1] - rand.random_num(24,28)*u + (u===0?rand.random_num(-5,0):0));
      canvas.vertex(canvas.width/2 + rand.random_num(65,70), mouthCoords[1] - rand.random_num(12,14)*u + (u===0?rand.random_num(-5,0):0));
      canvas.vertex(mouthCoords[2], mouthCoords[3]);
      canvas.vertex(canvas.width/2 + rand.random_num(68,72), mouthCoords[1] + rand.random_num(34,38)*b + (b===0?rand.random_num(0,5):0));
      canvas.vertex(canvas.width/2 + rand.random_num(33,37), mouthCoords[1] + rand.random_num(52,56)*b + (b===0?rand.random_num(0,5):0));
      canvas.vertex(canvas.width/2 + rand.random_num(11,15), mouthCoords[1] + rand.random_num(56,60)*b + (b===0?rand.random_num(0,5):0));
      canvas.vertex(canvas.width/2 + rand.random_num(-11,-7), mouthCoords[1] + rand.random_num(56,60)*b + (b===0?rand.random_num(0,5):0));
      canvas.vertex(canvas.width/2 + rand.random_num(-32,-28), mouthCoords[1] + rand.random_num(52,56)*b + (b===0?rand.random_num(0,5):0));
      canvas.vertex(canvas.width/2 + rand.random_num(-72,-68), mouthCoords[1] + rand.random_num(34,38)*b + (b===0?rand.random_num(0,5):0));
      canvas.fill(color);
      canvas.endShape(p5.CLOSE);
    };
  
    if (area) {
      mouthShape(canvas, "#000000");
    }
    else {
      mouthShape(canvas, "#00000080", 0, 0);
      canvas.beginShape();
      canvas.vertex(canvas.width/2 - 40, 616);
      canvas.curveVertex(canvas.width/2, 622);
      canvas.vertex(canvas.width/2 + 45, 616);
      canvas.curveVertex(canvas.width/2, 626);
      canvas.fill("#00000020");
      canvas.endShape(p5.CLOSE);
    };
  
    return mouthCoords;
  };
  
  function face(p5, canvas, shadow=false) {
    const a = canvas.width/2 + 2 +rand.random_num(-2,2);
    let y = shadow ? 1 : 0;
  
    canvas.beginShape();
    for (let i = 1; i>-2; i-=2) {
      let chin_pts = [];
      chin_pts.push([a, 718+y]);
      chin_pts.push([a+48*i+rand.random_num(-2,6), 710+rand.random_num(-2,2)+y]);
      chin_pts.push([a+93*i+rand.random_num(-2,6), 687+rand.random_num(-2,2)+y]);
      chin_pts.push([a+128*i+rand.random_num(-2,6), 660+rand.random_num(-2,2)+y]);
      chin_pts.push([a+154*i+rand.random_num(-2,6), 640+rand.random_num(-2,2)+y]);
      chin_pts.push([a+198*i, 600+y]);
      chin_pts.push([a+223*i, 564+y]);

      if (shadow) {
        if ((i===1 && y<5) || (i===-1 && y>5)) {chin_pts.reverse()};
      };

      for (let j =0; j<chin_pts.length; j++) {
        canvas.vertex(chin_pts[j][0], chin_pts[j][1]);
      };
  
      if (shadow) {
        if (i===-1 && y<5) {y+=10; i+=2;}
        else if (i===-1 && y>5) {i+=4;}
        else if (i=== 1 && y>5) {break;};
      } else {
        canvas.vertex(a+232*i, 540);
        canvas.vertex(a+242*i+rand.random_num(-2,6), 506+rand.random_num(-2,2));
        canvas.vertex(a+249*i, 460);
        canvas.vertex(a+258*i, 420);
        canvas.vertex(a+264*i+rand.random_num(-2,6), 380+rand.random_num(-2,2));
        canvas.vertex(a+267*i, 360);
        canvas.vertex(a+265*i, 320);
        canvas.vertex(a+260*i, 270);
        canvas.vertex(a+258*i+rand.random_num(-2,6), 230+rand.random_num(-2,2));
        canvas.vertex(a+250*i, 190);
        canvas.vertex(a+240*i, 160);
        canvas.vertex(a+228*i, 130);
        canvas.vertex(a+214*i+rand.random_num(-2,6), 100+rand.random_num(-2,2));
        canvas.vertex(a+186*i, 65);
        canvas.vertex(a+160*i, 45);
        canvas.vertex(a+130*i+rand.random_num(-2,6), 30+rand.random_num(-2,2));
        canvas.vertex(a+80*i, 24);
        canvas.vertex(a+30*i+rand.random_num(-2,6), 20+rand.random_num(-2,2));
        canvas.vertex(a, 22);
        canvas.vertex(a, 718);
      };
    };
    canvas.fill("#00000060");
    canvas.endShape(p5.CLOSE);
  };
  
  function head(p5, canvas, ear) {
    // Ear shadow
    if (ear) {
      let a = canvas.width/2 + 260;
      for (let i = 1; i>-2; i-=2) {
        canvas.beginShape();
        canvas.curveVertex(a+10*i, 412);
        canvas.curveVertex(a+20*i+rand.random_num(-2,2), 400);
        canvas.curveVertex(a+42*i+rand.random_num(-2,2), 385);
        canvas.curveVertex(a+60*i+rand.random_num(-2,2), 365);
        canvas.curveVertex(a+68*i+rand.random_num(-2,2), 310);
        canvas.curveVertex(a+62*i+rand.random_num(-2,2), 274);
        canvas.curveVertex(a+40*i+rand.random_num(-2,2), 286);
        canvas.curveVertex(a+20*i+rand.random_num(-2,2), 305);
        canvas.fill("#00000020");
        canvas.endShape(p5.CLOSE);
        a -= 516;
      };
      return;
    };

    face(p5, canvas);
  
    // Ear
    let a = canvas.width/2 + 245;
    for (let i = 1; i>-2; i-=2) {
      canvas.beginShape();
      canvas.curveVertex(a, 440);
      canvas.curveVertex(a+25*i+rand.random_num(-2,2), 428);
      canvas.curveVertex(a+50*i+rand.random_num(-2,2), 405);
      canvas.curveVertex(a+75*i+rand.random_num(-2,2), 376);
      canvas.curveVertex(a+92*i+rand.random_num(-2,2), 296);
      canvas.curveVertex(a+80*i+rand.random_num(-2,2), 256);
      canvas.curveVertex(a+35*i+rand.random_num(-2,2), 280);
      canvas.curveVertex(a+5*i+rand.random_num(-2,2), 300);
      canvas.fill("#000000");
      canvas.endShape(p5.CLOSE);
      a -= 490;
    };
  
    // Neck
    let b = canvas.width/2;
    canvas.beginShape();
    for (let i = 1; i>-2; i-=2) {
      const x = rand.random_num(-10,10);
      const y = rand.random_num(-25,15);
      canvas.vertex(b, canvas.height);
      canvas.vertex(b + b*i , canvas.height);
      canvas.vertex(b + b*i, canvas.height - rand.random_num(90, 95) + y);
      canvas.vertex(b + (b+x-rand.random_num(38,42))*i, canvas.height - rand.random_num(95, 100) + y);
      canvas.vertex(b + (b+x-rand.random_num(78,82))*i, canvas.height - rand.random_num(112, 117) + y);
      canvas.vertex(b + (b+x-rand.random_num(98,102))*i, canvas.height - rand.random_num(120, 124) + y);
      canvas.vertex(b + (b+x-rand.random_num(124,122))*i, canvas.height - rand.random_num(126, 130) + y);
  
      canvas.vertex(b + (b+x-rand.random_num(240,244))*i, canvas.height - rand.random_num(170, 175) + y);
      canvas.vertex(b + (b+x-rand.random_num(250,254))*i, canvas.height - 320);
      canvas.vertex(b, canvas.height - 320);
      canvas.vertex(b, canvas.height);
    };
    canvas.fill("#000000");
    canvas.endShape(p5.CLOSE);
  };
  
  function hair(p5, canvas, color_canvas, over=false, eyebrows=false, hairPalette_=null) {
    if (hairPalette_ == null) { hairPalette_ = hairPalette };

    const pixelsPerSegment = 100;
    const noiseScale = 120;
    const noiseFrequency = 0.01;
  
    canvas.noFill();
    canvas.strokeWeight(0.7);
  
    function thread(start_x, start_y, end_x, end_y, color="") {
      if (color==="") {
        color = color_canvas.get(rand.random_num((canvas.width/2)*0.25, (canvas.width/2)*0.5), rand.random_num((canvas.height/2)*0.25, (canvas.height/2)*0.5))
      };
      canvas.stroke(color);
  
      const start = p5.createVector(start_x, start_y);
      const end = p5.createVector(end_x, end_y);
  
      let lineLength = start.dist(end);
      let segments = p5.max(1, p5.round(lineLength / pixelsPerSegment));
      let points = 1 + segments;
  
      let angle = p5.atan2(end.y - start.y, end.x - start.x);
      let xInterval = pixelsPerSegment * p5.cos(angle);
      let yInterval = pixelsPerSegment * p5.sin(angle);
      canvas.beginShape();
      canvas.curveVertex(start.x, start.y);
      
      for (let i = 1; i < points - 1; i++) {
        let x = start.x + xInterval * i;
        let y = start.y + yInterval * i;
        let offset = noiseScale * p5.noise(i * pixelsPerSegment * noiseFrequency);
        let xOffset = offset * p5.cos(angle - p5.PI / 2);
        let yOffset = offset * p5.sin(angle - p5.PI / 2);
        canvas.curveVertex(x + xOffset, y + yOffset);
      }
      
      canvas.curveVertex(end.x, end.y);
      canvas.endShape();
    };
  
    if (over) {
      for (let i=0; i<1000; i++) {
        // Left
        let x_start = rand.random_num((canvas.width/2)*0.7, (canvas.width/2)*1.3);
        let y_start = rand.random_num(-120, -50);
        let x_end = rand.random_num((canvas.width/2)*0.1, (canvas.width/2)*0.2);
        let y_end = rand.random_num(200, 240);
        p5.noiseSeed(rand.random_choice([278 , 59 , -315 , 624 , 575 , -436 , 982 , -705]));
        thread(x_start, y_start, x_end, y_end, rand.random_choice(hairPalette_));
      };
      for (let i=0; i<1000; i++) {
        // Right
        let x_start = rand.random_num((canvas.width/2)*0.5, (canvas.width/2)*1.35);
        let y_start = rand.random_num(-60, -20);
        let x_end = rand.random_num((canvas.width/2)*1.58, (canvas.width/2)*1.65);
        let y_end = rand.random_num(300, 340);
        p5.noiseSeed(rand.random_choice([-896 , 692 , -35 , 278, 675 , -663 , 575 , 982 , -684 , -454 , -705]));
        thread(x_start, y_start, x_end, y_end, rand.random_choice(hairPalette_));
      };
    }
    else if (eyebrows) {
      for (let i=0; i<5000; i++) {
        let x_start = rand.random_num(0, canvas.width);
        let y_start = rand.random_num(200, 600);
        let x_end = rand.random_num(0, canvas.width);
        let y_end = rand.random_num(200, 600);
        p5.noiseSeed(rand.random_int(-1000,1000));
        thread(x_start, y_start, x_end, y_end, rand.random_choice(hairPalette_));
      };
    }
    else {
      for (let i=0; i<12000; i++) {
        let x_start = rand.random_num((canvas.width/2)*0.25, (canvas.width/2)*1.5);
        let y_start = rand.random_num(80, canvas.height-300);
        let x_end = rand.random_num(0, canvas.width);
        let y_end = canvas.height + rand.random_num(-20, 0);
        p5.noiseSeed(rand.random_int(-1000,1000));
        thread(x_start, y_start, x_end, y_end, rand.random_choice(hairPalette_));
      };
    };
  };


  return (
    <div> 

    <Box>
      <Desktop>
        <Sketch preload={preload} setup={(p5) => {setup(p5, 900)}} draw={draw} mouseMoved={mouseMoved} className="App" />
      </Desktop>
    
      <Tablet>
        <Sketch preload={preload} setup={(p5) => {setup(p5, p5.windowWidth-30)}} draw={draw} mouseMoved={mouseMoved} className="App" />
      </Tablet>

      <Mobile>
        <Sketch preload={preload} setup={(p5) => {setup(p5, p5.windowWidth-30)}} draw={draw} mouseMoved={mouseMoved} className="App" />
      </Mobile>

    </Box>

    </div>
  );
}

