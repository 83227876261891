import React, { useEffect, useState } from "react";
import Sketch from "react-p5";

import { Desktop, Tablet, Mobile } from '../../responsive';

import Header from "../Header/Header";
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Snackbar from '@mui/material/Snackbar';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';

const tokenData = {
    hash: "0x11ac16678959949c12d541021" + Math.floor(Math.random() * 11) + Math.floor(Math.random() * 11) + Math.floor(Math.random() * 10) + Math.floor(Math.random() * 10) + "960fc496813cbc3495bf77aeed738579" + Math.floor(Math.random() * 11) + Math.floor(Math.random() * 11) + Math.floor(Math.random() * 10),
    tokenId: "123000456"
}

class Random {
    constructor() {
      this.useA = false;
      let sfc32 = function (uint128Hex) {
        let a = parseInt(uint128Hex.substr(0, 8), 16);
        let b = parseInt(uint128Hex.substr(8, 8), 16);
        let c = parseInt(uint128Hex.substr(16, 8), 16);
        let d = parseInt(uint128Hex.substr(24, 8), 16);
        return function () {
          a |= 0; b |= 0; c |= 0; d |= 0;
          let t = (((a + b) | 0) + d) | 0;
          d = (d + 1) | 0;
          a = b ^ (b >>> 9);
          b = (c + (c << 3)) | 0;
          c = (c << 21) | (c >>> 11);
          c = (c + t) | 0;
          return (t >>> 0) / 4294967296;
        };
      };
      this.prngA = new sfc32(tokenData.hash.substr(2, 32));
      this.prngB = new sfc32(tokenData.hash.substr(34, 32));
      for (let i = 0; i < 1e6; i += 2) {
        this.prngA();
        this.prngB();
      }
    }
    random_dec() {
      this.useA = !this.useA;
      return this.useA ? this.prngA() : this.prngB();
    }
    random_num(a, b) {
      return a + (b - a) * this.random_dec();
    }
    random_int(a, b) {
      return Math.floor(this.random_num(a, b + 1));
    }
    random_bool(p) {
      return this.random_dec() < p;
    }
    random_choice(list) {
      return list[this.random_int(0, list.length - 1)];
    }
};


class Word {
    constructor(p5, rand, word, x, y, speed, clr) {
        this.p5 = p5
        this.rand = rand
        this.word = word
        this.x = x
        this.y = y
        this.targetx = this.x
        this.targety = this.y
        this.speed = speed
        this.fcolor = p5.color(clr)
        this.visible = true
    }

    redirect(x, y) {
        this.targetx = x
        this.targety = y
    }

    spread() {
        this.visible = true
        this.targetx = this.rand.random_num(0, this.p5.width)
        this.targety = this.rand.random_num(0, this.p5.height)
    }

    update() {
        this.x = this.p5.lerp(this.x, this.targetx, this.speed)
        this.y = this.p5.lerp(this.y, this.targety, this.speed)
    }

    display() {
      if (this.visible) {
        this.p5.fill(this.fcolor)
        this.p5.text(this.word, this.x, this.y)
      }
    }
};


export default function Consciousness(props) {

  const [snackbarOpen, setSnackbarOpen] = React.useState(true);


  var words_list = ["chaos", "love", "instinct", "intimacy", "alone", "solitude", "friendship", "proud",
    "friends", "pain", "betrayal", "loyalty", "simplicity", "family", "escape", "reality", "mind", "activism",
    "insomnia", "rejection", "happy", "happiness", "life", "death", "light", "darkness", "satisfied",
    "sad", "sadness", "people", "rebellion", "bestiality", "pure", "religion", "revenge", "privacy", "peace",
    "dreams", "hope", "inspiration", "motivation", "purpose", "passion", "hero", "shadows", "calm", "action",
    "attitude", "expectations", "belief", "faith", "deception", "nostalgia", "loss", "affection", "fear",
    "anger", "hatred", "harmony", "kindness", "evil", "gentle", "change", "indifference", "progress",
    "novelty", "fight", "conversation", "serious", "crazy", "mad", "speech", "honesty", "falsehood",
    "truth", "lies", "human", "fault", "mistaken", "success", "celebration", "conscious", "thought",
    "smile", "cry", "trouble", "brightness", "responsability", "fun", "childhood", "youth", "bitter",
    "grown", "mature", "time", "strenght", "weak", "vulnerability", "price", "cost", "choice", "decision",
    "attention", "feelings", "impulse", "desire", "wish", "cure", "vengeance", "reward", "violence",
    "pride", "knowledge", "mood", "work", "monday", "weekend", "music", "freedom", "fraud", "health",
    "persistance", "sacrifice", "ego", "laugh", "moment", "pleasure", "heaven", "hell", "rest",
    "mother", "father", "children", "spouse", "society", "relationship", "guilt"];


  const [rand, setRand] = useState();
  const [bgColor, setBgColor] = useState();
  const [colors, setColors] = useState();
  var [words, setWords] = useState([]);
  const [lastModTime, setLastModTime] = useState(-6000);
  var [word_count] = useState(0);

  var DEFAULT_SIZE = 1000
  var WIDTH = window.innerWidth
  var HEIGHT = window.innerHeight
  var DIM = Math.min(WIDTH, HEIGHT)
  var M = DIM / DEFAULT_SIZE

  const total_words = 8;


  useEffect(() => {
    const new_rand = new Random();
    setRand(new_rand);
    let newBgColor, newColors;
    [newBgColor, newColors] = getColors(new_rand);
    setBgColor(newBgColor);
    setColors(newColors);
  }, []);

  function handleSnackbarClose(event, reason) {
    if (reason) {
      if (reason === 'clickaway') {
        return;
      };
    };
    
    setSnackbarOpen(false);
  };


  function setup(p5) {
    p5.createCanvas(WIDTH, HEIGHT);  
    p5.background(bgColor);
    p5.noStroke();

    createWords(p5, 500, colors);
  };

  function draw(p5) {
    p5.background(bgColor);

    for (let i = 0; i < words.length; i++) {
        const word = words[i];
        word.update();
        word.display();
    };

    if (p5.millis()-lastModTime > 12000 && word_count<total_words) {
      setLastModTime(p5.millis());
      if (rand.random_bool(0.7)) {
        formWord(p5);
        word_count++;
      } 
      else {
        spreadWords();
      }
    }
  }

  function keyPressed(p5) {
    if (p5.key === 'r') {
      setLastModTime(p5.millis());
      spreadWords();
    } 
    else if (p5.key === ' ') {
      setLastModTime(p5.millis());
      formWord(p5);
    };
  }

  function createWords(p5, num=400, clrs=["#000000"]) {
    defaultSettings(p5);
    for (let i = 0; i < num; i++) {
      let word_str = words_list[rand.random_int(0, words_list.length-1)];
      let speed = rand.random_num(0.04, 0.1);
      const word = new Word(p5, rand, word_str, rand.random_int(0, p5.width), rand.random_int(0, p5.height), speed, clrs[rand.random_int(0,clrs.length-1)]);
      words.push(word);
    };
  }

  function wordMask(p5) {
    const word = words_list.splice(rand.random_int(0, words_list.length-1), 1)[0];

    let main_graph = p5.createGraphics(WIDTH, HEIGHT);
    main_graph.textAlign(p5.CENTER, p5.CENTER);
    main_graph.textStyle(p5.BOLD);

    let fontSize = 240;
    main_graph.textSize(fontSize);

    while (main_graph.textWidth(word) < WIDTH*0.8 && fontSize<500) {
      fontSize += 5;
      main_graph.textSize(fontSize);
    };

    while (main_graph.textWidth(word) > WIDTH*1) {
      fontSize -= 5;
      main_graph.textSize(fontSize);
    };

    main_graph.textSize(fontSize);
    main_graph.text(word, WIDTH/2, HEIGHT/2);

    return main_graph;
  }

  function formWord(p5) {
    let fontSize = defaultSettings(p5);
  
    let mask = wordMask(p5);
    mask.textSize(fontSize);
    mask.textFont('Courier New');
  
    var rectangles = [];
  
    for (let i = 0; i < words.length; i++) {
      let word = words[i];
      let word_str = word.word;
  
      let x = Math.round(rand.random_int(0, p5.width) / 10) * 10;
      let y = Math.round(rand.random_int(0, p5.height) / 10) * 10;
  
      let j = 0;
      while(mask.get(x,y)[3] == "0" || inRectangles(x, y, p5.textWidth(word_str), rectangles)) {
        x = Math.round(rand.random_int(0, p5.width) / 10) * 10;
        y = Math.round(rand.random_int(0, p5.height) / 10) * 10;
        if (j>2000) {break};
        j++;
      };
      
      if (j<500) {
        word.redirect(x, y);
        word.visible = true;
        rectangles.push([x, y, p5.textWidth(word_str)]);
      }
      else {
        for (let k=i; k<words.length; k++) {
          words[k].visible = false;
        };
        break;
      };
    };
  }

  function spreadWords() {
    for (let word of words) word.spread();
  }
  
  function inRectangles(x, y, w, rectangles) {
    if (rectangles.length === 0) { return false; }
  
    for (let i=0; i<rectangles.length; i++) {
      const rectangle = rectangles[i];
      if (y === rectangle[1] && x+w/2 >= rectangle[0]-rectangle[2]/2 && x-w/2 <= rectangle[0]+rectangle[2]/2) { return true;}
    };
  
    return false;
  }

  function defaultSettings(p5, fontSize = 5.5) {
    p5.textSize(fontSize);
    p5.textFont('Courier New');
    p5.textAlign(p5.CENTER, p5.CENTER);
    p5.textStyle(p5.BOLD);
    return fontSize;
  }

  function getColors(rand) {
    let background_color, text_color;
    background_color = rand.random_choice(["#010920", "#000000", "#1f0608", "#33483f", "#284976", "#2d0833", "#393e04", "#531603", "#033236"]);
  
    let txt_rand = rand.random_num(0, 100);
    
    if (txt_rand > 90) {
      text_color = ["#ffffff", "#efc7c2", "#bfd3c1", "#68a691", "#694f5d"];
    }
    else if (txt_rand > 80) {
      text_color = ["#ffffff", "#ff0000", "#f8037a"];
    }
    else if (txt_rand > 70) {
      text_color = ["#ffffff", "#3d5a80", "#98c1d9", "#ee6c4d", "#293241"];    
    }
    else if (txt_rand > 60) {
      text_color = ["#ffffff", "#d7bb3b", "#3b57d7", "#697195", "#958d69"];    
    }
    else if (txt_rand > 50) {
      text_color = ["#ffffff", "#5f0f40", "#9a031e", "#e36414", "#0f4c5c"];    
    }
    else if (txt_rand > 40) {
      text_color = ["#ffffff", "#cb997e", "#ffe8d6", "#b7b7a4", "#6b705c"];    
    }
    else if (txt_rand > 30) {
      text_color = ["#ffffff", "#d4e09b", "#cbdfbd", "#f19c79", "#a44a3f"];    
    }
    else if (txt_rand > 20) {
      text_color = ["#ffffff", "#ffb4a2", "#e5989b", "#b5838d", "#6d6875"];    
    }
    else if (txt_rand > 10) {
      text_color = ["#ffffff", "#dde5b6", "#adc178", "#a98467", "#6c584c"];    
    }
    else {
      text_color = ["#ffffff"];
    };
  
    
    return [background_color, text_color];
  }

  return (
    <div>
      
    <Snackbar
      open={snackbarOpen}
      anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      autoHideDuration={10000}
      onClose={handleSnackbarClose}
      message={
          <Box>
            <Typography sx={{ color: "#ffffff"}}>Press R key to shuffle the words, and Space key to form a new word</Typography>
            <Typography sx={{ color: "#ffffff"}}>Refresh the page to change the color selection</Typography>
          </Box>
        }

      action={
        <IconButton
          size="small"
          aria-label="close"
          color="inherit"
          onClick={handleSnackbarClose}
        >
          <CloseIcon fontSize="small" />
        </IconButton>
        }
    /> 

    <Box>
      <Desktop>
        <Sketch setup={setup} draw={draw} keyPressed={keyPressed} className="App" />
      </Desktop>
    
      <Tablet>
        <Sketch setup={setup} draw={draw} keyPressed={keyPressed} className="App" />
      </Tablet>

      <Mobile>
        <Sketch setup={setup} draw={draw} keyPressed={keyPressed} className="App" />
      </Mobile>

    </Box>

    </div>
  );
}

