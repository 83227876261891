import React from "react";
import { Desktop, Tablet, Mobile } from '../../responsive'

import Header from "../Header/Header";
import Footer from "../Footer/Footer";

import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import Image from 'react-image-resizer';


export default function About() {


  // Old Ballerina text:
  // Why would the fiddler play if not for her to dance? With a sweet smile and bright curious eyes, it's impossible not to be enchanted by her. She's a decided woman. All the art is carefully selected by her and nothing is done without her approval. Once she has started to dance nothing can stop her. So graceful she is, the orchestra's music seems to be silent while she is dancing.

  return (
    <div>
    <Header />

    <Desktop>
      <Box sx={{ px: "16%", mt: "10px" }}>
        <Box sx={{ width: "360px", height: "150px", mb: "-10px" }}>
          <Typography sx={{ color: "#222", fontSize: "36px", fontWeight: "500" }}>
            ABOUT
          </Typography>
        </Box>

        <Typography sx={{ color: "#222", fontSize: "24px", fontWeight: "500", mb: "30px" }}>
          THE TRIO
        </Typography>

        <Stack direction="row" spacing={12} >
          <Box sx={{ width: "360px", height: "380px", backgroundColor: '#f8f8f8' }}>
            <Image src={require("../../assets/violinist.png")} height="360" width="360" />
          </Box>

          <Box sx={{ width: "360px", height: "300px", ml: "160px", px: "16px", py: "0px" }}>
            <Typography sx={{ color: "#222", fontSize: "24px", fontWeight: "500" }}>
              The Fiddler
            </Typography>

            <Typography sx={{ color: "#222", fontSize: "16px", mt: "20px", lineHeight: 1.7 }}>
              The main artist. In the midst of his caotic work, he turns functions into shapes; numbers into art. The keys on his computer are his violin cords, each line of code is a musical note; a brush stroke. He loves coding and does it like nobody else. Never without his dear notebook, he is always learning something new. No problem is to hard for him to solve, nothing can stop him from achieving his most crazy and impossible projects. He is just really bad at giving up.
            </Typography>
          </Box>
        </Stack>

        <Stack direction="row" spacing={12} mt="150px">
          <Box sx={{ width: "360px", height: "380px", backgroundColor: '#f8f8f8' }}>
            <Image src={require("../../assets/pianist.png")} height="360" width="360" />
          </Box>

          <Box sx={{ width: "360px", height: "400px", ml: "160px", px: "16px", py: "36px" }}>
            <Typography sx={{ color: "#222", fontSize: "24px", fontWeight: "500" }}>
              The Pianist
            </Typography>

            <Typography sx={{ color: "#222", fontSize: "16px", mt: "20px", lineHeight: 1.7 }}>
              Gives the tone for the whole orchestra. He is the first to arrive at the stage and the last one to leave. Totally devoted to his projects, he works non stop to make sure everything is perfect. Can't sleep or even rest until a bug in the code is fixed or an obstacle to the project is removed. He always plays with the piano cover open, as in his life, always with an open hand for anyone who needs help.
            </Typography>
          </Box>

        </Stack>

        <Stack direction="row" spacing={12} mt="150px">
          <Box sx={{ width: "360px", height: "380px", backgroundColor: '#f8f8f8' }}>
            <Image src={require("../../assets/ballet.png")} height="360" width="360" />
          </Box>

          <Box sx={{ width: "360px", height: "400px", ml: "160px", px: "16px", py: "36px" }}>
            <Typography sx={{ color: "#222", fontSize: "24px", fontWeight: "500" }}>
              The Ballerina
            </Typography>

            <Typography sx={{ color: "#222", fontSize: "16px", mt: "20px", lineHeight: 1.7 }}>
              Why would the fiddler play if not for her to dance? With a sweet smile and bright curious eyes, it's impossible not to be enchanted by her. An amazing woman, fearless and independent, who stands for her values and beliefs. She sees beauty on all simple things, the world through her eyes is absolutely <i>magnifique</i>. So graceful she is, the orchestra's music seems to be silent while she is dancing.
            </Typography>
          </Box>

        </Stack>

        <Box mt="160px">
          <Typography sx={{ color: "#222", fontSize: "24px", fontWeight: "500" }}>
            WHY
          </Typography>

          <Typography sx={{ color: "#222", fontSize: "16px", mt: "12px", lineHeight: 1.7 }}>
            None of us ever thought we could become artists one day. We all have no talent at drawing or painting, but we are good at coding. We started generating some images as a hobbie, but then it became our passion. We began spending whole nights together improving our algorithms and testing new ideas. When we first saw the output of Davos, we knew it was different from everything we have seen so far. We decided to work formally as a team on April 2022 and never stopped coding together since then.
          </Typography>
        </Box>

        <Box mt="160px">
          <Typography sx={{ color: "#222", fontSize: "24px", fontWeight: "500" }}>
            MAKING THE WORLD A BETTER PLACE
          </Typography>

          <Typography sx={{ color: "#222", fontSize: "16px", mt: "12px", lineHeight: 1.7 }}>
            We strongly believe anyone can contribute to a better world. The world is a marvelous place, with breathtaking sights and wonderful experiences, but unfortunatelly not everyone has the same opportunities to enjoy it at full extent. In fact, many people in the world don't even have food and proper habitation, which is the very least a person needs to live. In view of that, we will be donating at least 20% of our profit from all our collections to selected charity fundations, that will be listed in here very soon. We will focus our donations to fight hunger and to improve education in poor countries.
          </Typography>
        </Box>

        <Box mt="160px">
          <Typography sx={{ color: "#222", fontSize: "24px", fontWeight: "500" }}>
            NEXT STEP
          </Typography>

          <Typography sx={{ color: "#222", fontSize: "16px", mt: "12px", lineHeight: 1.7 }}>
            After Davos we look forward to create algorithms that combine real world figures with Davos art, generating a sequence of vivid and colorful images. Meanwhile, we are nearly finishing our two generative art collections, coded using the p5.js library; both are animated and have a unique style. After that, we will engage on creating better and better generative art algorithms everytime, using new technologies and some other programming languages as well. In a few months we might even be creating music and 3D objects, totally generated by code, who knows? We will explore the limits of our creativity.
          </Typography>
        </Box>

      </Box>
    </Desktop>

    <Tablet>
      <Box sx={{ px: "10%", mt: "40px" }}>
        <Box sx={{ width: "360px", height: "150px", mb: "-30px" }}>
          <Typography sx={{ color: "#222", fontSize: "30px", fontWeight: "500" }}>
            ABOUT
          </Typography>
        </Box>

        <Typography sx={{ color: "#222", fontSize: "22px", fontWeight: "500", mb: "30px" }}>
          THE TRIO
        </Typography>

        <Box sx={{ width: "300px", height: "320px", backgroundColor: '#f8f8f8' }}>
          <Image src={require("../../assets/violinist.png")} height="300" width="300" />
        </Box>

        <Box sx={{ width: "360px", height: "400px", py: "36px" }}>
          <Typography sx={{ color: "#222", fontSize: "20px", fontWeight: "500" }}>
            The Fiddler
          </Typography>

          <Typography sx={{ color: "#222", fontSize: "14px", mt: "20px", lineHeight: 1.7 }}>
            The main artist. In the midst of his caotic work, he turns functions into shapes; numbers into art. The keys on his computer are his violin cords, each line of code is a musical note; a brush stroke. He loves coding and does it like nobody else. Never without his dear notebook, he is always learning something new. No problem is to hard for him to solve, nothing can stop him from achieving his most crazy and impossible projects. He is just really bad at giving up.
          </Typography>
        </Box>

        <Box sx={{ width: "300px", height: "320px", backgroundColor: '#f8f8f8' }}>
          <Image src={require("../../assets/pianist.png")} height="300" width="300" />
        </Box>

        <Box sx={{ width: "360px", height: "400px", py: "36px" }}>
          <Typography sx={{ color: "#222", fontSize: "20px", fontWeight: "500" }}>
            The Pianist
          </Typography>

          <Typography sx={{ color: "#222", fontSize: "14px", mt: "20px", lineHeight: 1.7 }}>
            Gives the tone for the whole orchestra. He is the first to arrive at the stage and the last one to leave. Totally devoted to his projects, he works non stop to make sure everything is perfect. Can't sleep or even rest until a bug in the code is fixed or an obstacle to the project is removed. He always plays with the piano cover open, as in his life, always with an open hand for anyone who needs help.
          </Typography>
        </Box>

        <Box sx={{ width: "300px", height: "320px", backgroundColor: '#f8f8f8' }}>
          <Image src={require("../../assets/ballet.png")} height="300" width="300" />
        </Box>

        <Box sx={{ width: "360px", height: "400px", py: "36px" }}>
          <Typography sx={{ color: "#222", fontSize: "20px", fontWeight: "500" }}>
            The Ballerina
          </Typography>

          <Typography sx={{ color: "#222", fontSize: "14px", mt: "20px", lineHeight: 1.7 }}>
            Why would the fiddler play if not for her to dance? With a sweet smile and bright curious eyes, it's impossible not to be enchanted by her. An amazing woman, fearless and independent, who stands for her values and beliefs. She sees beauty on all simple things, the world through her eyes is absolutely <i>magnifique</i>. So graceful she is, the orchestra's music seems to be silent while she is dancing.
          </Typography>
        </Box>

        <Box mt="30px">
          <Typography sx={{ color: "#222", fontSize: "22px", fontWeight: "500" }}>
            WHY
          </Typography>

          <Typography sx={{ color: "#222", fontSize: "14px", mt: "12px", lineHeight: 1.7 }}>
            None of us ever thought we could become artists one day. We all have no talent at drawing or painting, but we are good at coding. We started generating some images as a hobbie, but then it became our passion. We began spending whole nights together improving our algorithms and testing new ideas. When we first saw the output of Davos, we knew it was different from everything we have seen so far. We decided to work formally as a team on April 2022 and never stopped coding together since then.
          </Typography>
        </Box>

        <Box mt="160px">
          <Typography sx={{ color: "#222", fontSize: "22px", fontWeight: "500" }}>
            MAKING THE WORLD A BETTER PLACE
          </Typography>

          <Typography sx={{ color: "#222", fontSize: "14px", mt: "12px", lineHeight: 1.7 }}>
            We strongly believe anyone can contribute to a better world. The world is a marvelous place, with breathtaking sights and wonderful experiences, but unfortunatelly not everyone has the same opportunities to enjoy it at full extent. In fact, many people in the world don't even have food and proper habitation, which is the very least a person needs to live. In view of that, we will be donating at least 20% of our profit from all our collections to selected charity fundations, that will be listed in here very soon. We will focus our donations to fight hunger and to improve education in poor countries.
          </Typography>
        </Box>

        <Box mt="160px">
          <Typography sx={{ color: "#222", fontSize: "22px", fontWeight: "500" }}>
            NEXT STEP
          </Typography>

          <Typography sx={{ color: "#222", fontSize: "14px", mt: "12px", lineHeight: 1.7 }}>
            After Davos we look forward to create algorithms that combine real world figures with Davos art, generating a sequence of vivid and colorful images. Meanwhile, we are nearly finishing our two generative art collections, coded using the p5.js library; both are animated and have a unique style. After that, we will engage on creating better and better generative art algorithms everytime, using new technologies and some other programming languages as well. In a few months we might even be creating music and 3D objects, totally generated by code, who knows? We will explore the limits of our creativity.
          </Typography>
        </Box>

      </Box>
    </Tablet>

    <Mobile>
      <Box sx={{ px: "8%", mt: "80px" }}>
        <Box sx={{ width: "300px", height: "150px", mb: "-30px" }}>
          <Typography sx={{ color: "#222", fontSize: "30px", fontWeight: "500" }}>
            ABOUT
          </Typography>
        </Box>

        <Typography sx={{ color: "#222", fontSize: "22px", fontWeight: "500", mb: "30px" }}>
          THE TRIO
        </Typography>

        <Box sx={{ width: "300px", height: "320px", backgroundColor: '#f8f8f8' }}>
          <Image src={require("../../assets/violinist.png")} height="300" width="300" />
        </Box>

        <Box sx={{ width: "300px", height: "440px", py: "36px" }}>
          <Typography sx={{ color: "#222", fontSize: "20px", fontWeight: "500" }}>
            The Fiddler
          </Typography>

          <Typography sx={{ color: "#222", fontSize: "14px", mt: "20px", lineHeight: 1.7 }}>
            The main artist. In the midst of his caotic work, he turns functions into shapes; numbers into art. The keys on his computer are his violin cords, each line of code is a musical note; a brush stroke. He loves coding and does it like nobody else. Never without his dear notebook, he is always learning something new. No problem is to hard for him to solve, nothing can stop him from achieving his most crazy and impossible projects. He is just really bad at giving up.
          </Typography>
        </Box>

        <Box sx={{ width: "300px", height: "320px", backgroundColor: '#f8f8f8' }}>
          <Image src={require("../../assets/pianist.png")} height="300" width="300" />
        </Box>

        <Box sx={{ width: "300px", height: "400px", py: "36px" }}>
          <Typography sx={{ color: "#222", fontSize: "20px", fontWeight: "500" }}>
            The Pianist
          </Typography>

          <Typography sx={{ color: "#222", fontSize: "14px", mt: "20px", lineHeight: 1.7 }}>
            Gives the tone for the whole orchestra. He is the first to arrive at the stage and the last one to leave. Totally devoted to his projects, he works non stop to make sure everything is perfect. Can't sleep or even rest until a bug in the code is fixed or an obstacle to the project is removed. He always plays with the piano cover open, as in his life, always with an open hand for anyone who needs help.
          </Typography>
        </Box>

        <Box sx={{ width: "300px", height: "320px", backgroundColor: '#f8f8f8' }}>
          <Image src={require("../../assets/ballet.png")} height="300" width="300" />
        </Box>

        <Box sx={{ width: "300px", height: "400px", py: "36px" }}>
          <Typography sx={{ color: "#222", fontSize: "20px", fontWeight: "500" }}>
            The Ballerina
          </Typography>

          <Typography sx={{ color: "#222", fontSize: "14px", mt: "20px", lineHeight: 1.7 }}>
            Why would the fiddler play if not for her to dance? With a sweet smile and bright curious eyes, it's impossible not to be enchanted by her. An amazing woman, fearless and independent, who stands for her values and beliefs. She sees beauty on all simple things, the world through her eyes is absolutely <i>magnifique</i>. So graceful she is, the orchestra's music seems to be silent while she is dancing.
          </Typography>
        </Box>

        <Box mt="30px">
          <Typography sx={{ color: "#222", fontSize: "22px", fontWeight: "500" }}>
            WHY
          </Typography>

          <Typography sx={{ color: "#222", fontSize: "14px", mt: "12px", lineHeight: 1.7 }}>
            None of us ever thought we could become artists one day. We all have no talent at drawing or painting, but we are good at coding. We started generating some images as a hobbie, but then it became our passion. We began spending whole nights together improving our algorithms and testing new ideas. When we first saw the output of Davos, we knew it was different from everything we have seen so far. We decided to work formally as a team on April 2022 and never stopped coding together since then.
          </Typography>
        </Box>

        <Box mt="160px">
          <Typography sx={{ color: "#222", fontSize: "22px", fontWeight: "500" }}>
            MAKING THE WORLD A BETTER PLACE
          </Typography>

          <Typography sx={{ color: "#222", fontSize: "14px", mt: "12px", lineHeight: 1.7 }}>
            We strongly believe anyone can contribute to a better world. The world is a marvelous place, with breathtaking sights and wonderful experiences, but unfortunatelly not everyone has the same opportunities to enjoy it at full extent. In fact, many people in the world don't even have food and proper habitation, which is the very least a person needs to live. In view of that, we will be donating at least 20% of our profit from all our collections to selected charity fundations, that will be listed in here very soon. We will focus our donations to fight hunger and to improve education in poor countries.
          </Typography>
        </Box>

        <Box mt="160px" mb="60px">
          <Typography sx={{ color: "#222", fontSize: "22px", fontWeight: "500" }}>
            NEXT STEP
          </Typography>

          <Typography sx={{ color: "#222", fontSize: "14px", mt: "12px", lineHeight: 1.7 }}>
            After Davos we look forward to create algorithms that combine real world figures with Davos art, generating a sequence of vivid and colorful images. Meanwhile, we are nearly finishing our two generative art collections, coded using the p5.js library; both are animated and have a unique style. After that, we will engage on creating better and better generative art algorithms everytime, using new technologies and some other programming languages as well. In a few months we might even be creating music and 3D objects, totally generated by code, who knows? We will explore the limits of our creativity.
          </Typography>
        </Box>

      </Box>
    </Mobile>

    <Footer />
    </div>
  );
}
