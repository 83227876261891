import React, { useEffect, useState } from "react";
import Sketch from "react-p5";

import { Desktop, Tablet, Mobile } from '../../responsive';

import Box from '@mui/material/Box';

const genRanHex = size => [...Array(size)]
  .map(() => Math.floor(Math.random() * 16).toString(16)).join('');

const tokenData = {
    hash: "0x"+genRanHex(65),
    tokenId: "123000456"
};

class Random {
    constructor() {
      this.useA = false;
      let sfc32 = function (uint128Hex) {
        let a = parseInt(uint128Hex.substr(0, 8), 16);
        let b = parseInt(uint128Hex.substr(8, 8), 16);
        let c = parseInt(uint128Hex.substr(16, 8), 16);
        let d = parseInt(uint128Hex.substr(24, 8), 16);
        return function () {
          a |= 0; b |= 0; c |= 0; d |= 0;
          let t = (((a + b) | 0) + d) | 0;
          d = (d + 1) | 0;
          a = b ^ (b >>> 9);
          b = (c + (c << 3)) | 0;
          c = (c << 21) | (c >>> 11);
          c = (c + t) | 0;
          return (t >>> 0) / 4294967296;
        };
      };
      this.prngA = new sfc32(tokenData.hash.substr(2, 32));
      this.prngB = new sfc32(tokenData.hash.substr(34, 32));
      for (let i = 0; i < 1e6; i += 2) {
        this.prngA();
        this.prngB();
      }
    }
    random_dec() {
      this.useA = !this.useA;
      return this.useA ? this.prngA() : this.prngB();
    }
    random_num(a, b) {
      return a + (b - a) * this.random_dec();
    }
    random_int(a, b) {
      return Math.floor(this.random_num(a, b + 1));
    }
    random_bool(p) {
      return this.random_dec() < p;
    }
    random_choice(list) {
      return list[this.random_int(0, list.length - 1)];
    }
};

class Shape {
    constructor(p5, rand, img, x, y) {
        this.p5 = p5
        this.rand = rand
        this.img = img
        this.x = x
        this.y = y
        this.targetx = this.x
        this.targety = this.y
        this.speed = this.rand.random_num(0.01, 0.03)
        this.scale = 0.5
        this.growingRate = this.rand.random_num(0.015, 0.04)
    }
  
    redirect() {
        this.targetx = this.x + this.rand.random_num(-30, 30)
        this.targety = this.y + this.rand.random_num(-30, 30)
    }
  
    update() {
        if (this.scale < 1) {        
          this.scale += this.growingRate
        }
  
        this.x = this.p5.lerp(this.x, this.targetx, this.speed)
        this.y = this.p5.lerp(this.y, this.targety, this.speed)
    }
  
    display() {
        let displayImg = this.img;
  
        if (this.scale < 1) {
          displayImg = this.duplicateImg(this.img)
          displayImg.resize(Math.round(this.img.width*this.scale), Math.round(this.img.width*this.scale))
        }
  
        this.p5.image(displayImg, this.x, this.y)
    }

    duplicateImg(tempImg) {
        let canvas = this.p5.createGraphics(tempImg.width, tempImg.height);
        canvas.image(tempImg, 0, 0);
        return canvas.get();
    }
};
  

export default function StarDust() {
    
    const [rand, setRand] = useState();
    const [palletteName, setPaletteName] = useState();
    const [palette, setPalette] = useState();
    const [distributionName, setDistributionName] = useState();
    const [colorDistribution, setColorDistribution] = useState();
    const [curvesAllowed, setCurvesAllowed] = useState();
    const [centered, setCentered] = useState();
    const [externalColorsRate, setExternalColorsRate] = useState();
    const [totalShapes, setTotalShapes] = useState();
    const [drawingTime, setDrawingTime] = useState();
    const [bgColor, setBgColor] = useState();
    const [shapesList, setShapesList] = useState([]);

    var drawnShapes = useState(0);
    var externalColors = useState(0);


    useEffect(() => {
        const new_rand = new Random();
        setRand(new_rand);

        const [newDistributionName, newColorDistribution] = getColorDistribution(new_rand);
        setDistributionName(newDistributionName);
        setColorDistribution(newColorDistribution);

        setCurvesAllowed(new_rand.random_bool(0.5));
        setCentered(new_rand.random_bool(0.5));
        setExternalColorsRate(new_rand.random_num(0, 30));
        setTotalShapes(new_rand.random_int(80, 180));
        setDrawingTime(new_rand.random_int(30, 45));
      }, []);

    
    function setup(p5) {    
        const new_rand = new Random();

        const [newPaletteName, newPalette] = getPalette(new_rand);
        setPaletteName(newPaletteName);
        setPalette(newPalette);

        p5.createCanvas(p5.windowWidth, p5.windowHeight);

        let bcColor = newPalette.splice(new_rand.random_int(0, 4), 1);
        if (new_rand.random_bool(0.1)) {
            bcColor = new_rand.random_choice(["#ffffff", "#000000", "#0a0a0a", "#0b0512"]); 
        };
        setBgColor(bcColor)
    };
    
    
    function coloredShape(p5, opacity) {
    
        function getNearPoint(p5, points_list, center, radius) {
            let point = points_list[rand.random_int(0, points_list.length-1)];
          
            if (p5.dist(point[0], point[1], center[0], center[1]) > radius) {
              return getNearPoint(p5, points_list, center, radius);
            };
           
            return point;  
        };
    
        const fillColor = getColor(rand) + opacity;
    
        let canvas = p5.createGraphics(500, 500);
        
        if (rand.random_bool(0.2)) {
            canvas.stroke(fillColor.slice(0, fillColor.length - 2) + String(rand.random_int(30, 60)))
        }
        else {
            canvas.noStroke();
        }
    
        let points_list = []
        for (let i=0; i < rand.random_int(10, 30); i++) {
          points_list.push([rand.random_num(0, canvas.width), rand.random_num(0, canvas.height)])
        };
        
        for (let i=0; i < rand.random_int(60, 100); i++) {
            canvas.beginShape();
            let last_point = points_list[rand.random_int(0, points_list.length-1)];
    
            for (let j=0; j < rand.random_int(4,8); j++) {
                let point = getNearPoint(p5, points_list, last_point, rand.random_num(200,300))
                if (rand.random_bool(0.5)) { last_point = point; }; 
    
                curvesAllowed ? canvas.curveVertex(point[0], point[1]) : canvas.vertex(point[0], point[1]);
            };
            canvas.fill(fillColor);
            canvas.endShape(p5.CLOSE);
        };
    
        return canvas.get();
    };
    
    
    function getPalette(rand) {
        const palettes = [
            ["agate", ["#a47053", "#efca66", "#ecdab9", "#cec3c8", "#909cac"]],
            ["wonderstone", ["#e48826", "#bb99b7", "#ecc8c9", "#c6a78f", "#a0b3a8"]],
            ["amber", ["#6f2205", "#a36c22", "#eacc97", "#dee8ec", "#93b0d0"]],
            ["jasper", ["#f6d2ac", "#e6ae74", "#d6834f", "#a35233", "#42281c"]],
            ["rhodonite", ["#d73d6c", "#d57276", "#d6c2bc", "#c0cccc", "#65b2c6"]],
            ["amazonite", ["#e76f3d", "#feab6b", "#f3e9e7", "#9bcfe0", "#00a7c7"]],
            ["tanzanite", ["#bfd2de", "#e3c09b", "#d7cbd4", "#bb7db2", "#6f6c9e"]],
            ["beryl", ["#9dcbff", "#bfe2fe", "#fecdcb", "#df7e8a", "#8d1c1a"]],
            ["chrysoprase", ["#5b828e", "#bbcfd7", "#d2c8bc", "#ba9a88", "#ac7e62"]],
            ["malachite", ["#528e86", "#b7ced2", "#dcdde2", "#a19a90", "#636250"]],
            ["pearl", ["#97cded", "#02a0da", "#b8afc9", "#eae1ef", "#eadbd7"]],
            ["apatite", ["#573625", "#f7e8e3", "#d1dcde", "#0b8eab", "#04323a"]],
            ["anyolite", ["#a63d4c", "#c8777b", "#dcaab1", "#636f44", "#204429"]],
            ["lepidolite", ["#7f5b5a", "#aa646c", "#dbb5bb", "#e7c8ba", "#958948"]],
            ["variscite", ["#528c83", "#a2d4df", "#dddde2", "#e5a484", "#975536"]],
            ["ametrine", ["#22161c", "#6e304b", "#a37c82", "#e2ae6c", "#f2f1ef"]],
            ["heliodor", ["#7c7b89", "#f1e4de", "#f4d75e", "#e9723d", "#0b7fab"]],
            ["quartz", ["#1c0f0b", "#ab6e50", "#e4b18e", "#fff5e7", "#e9f3fb"]],
            ["opal", ["#f9de59", "#e8a628", "#f98365", "#c33124", "#a1dffb"]],
            ["jade", ["#fab73d", "#fddca5", "#bbc8ba", "#546747", "#2b331f"]],
            ["citrine", ["#c43d16", "#fd9a7e", "#edc596", "#fcb500", "#dc6d02"]],
            ["ruby", ["#770101", "#b00637", "#e5ab2d", "#e4cda4", "#f8f4f1"]],
            ["azurite", ["#012e67", "#9cacbf", "#2b6684", "#032e42", "#0a1417"]],
            ["emerald", ["#6e9a44", "#bad072", "#d5e2ed", "#3d9690", "#023026"]],
            ["amethyst", ["#d5ddef", "#bcb8ce", "#917898", "#4c394f", "#2e1a1e"]],
            ["sphalerite", ["#565462", "#84693b", "#304e60", "#141a23", "#040507"]],
            ["sugilite", ["#990726", "#350b1f", "#923367", "#c997c0", "#d6ccd5"]],
            ["sapphire", ["#490009", "#ac0e28", "#bc4558", "#013766", "#010a1c"]],
            ["alexandrite", ["#2b486a", "#869ba0", "#a39bb0", "#d2bba2", "#5a7702"]],
            ["fluorite", ["#b8e3ea", "#a9bcc6", "#c7aabc", "#513e5c", "#02334a"]],
            ["onyx", ["#d9ebe9", "#798f8c", "#44535e", "#0e1821", "#000000"]],
            ["hematite", ["#f0f1f4", "#d3d4d6", "#899593", "#2c3632", "#030303"]],
            ["moonstone", ["#b4b4af", "#bad5dc", "#e3e8ee", "#d4dfe5", "#a5b4b9"]],
            ["zircon", ["#274b69", "#85a1c1", "#c6ccd8", "#3f4d63", "#202022"]],
            ["aventurine", ["#80371a", "#dca780", "#a5adb7", "#50707b", "#111d1e"]],
            ["goshenite", ["#495464", "#bbbfca", "#f4f4f2", "#e8e8e8", "#bbbbbe"]],
            ["kunzite", ["#b58890", "#cdb6bc", "#cdc09b", "#babbce", "#565a75"]]
        ];
    
        return rand.random_choice(palettes);
    };
    
    function getColorDistribution(rand) {
        const distribution_options = [
            ["Sonata", [70, 15, 10, 5]],
            ["Concerto", [50, 20, 20, 10]],
            ["Cantata", [40, 20, 20, 20]],
            ["Fugue", [40, 40, 10, 10]],
            ["Symphony", [25, 25, 25, 25]]
        ];
    
        const [distName, distColors] = rand.random_choice(distribution_options);
        const shuffleDist = distColors.sort((a, b) => 0.5 - rand.random_num(0, 1));
        return [distName, shuffleDist];
    };
    
    function getColor(rand) {
        if (rand.random_bool(externalColorsRate/100)) {
            externalColors++;
            const externalPalette = getPalette(rand);
            return rand.random_choice(externalPalette[1]);
        }
    
        const colorNum = rand.random_int(0, 100)
        if (colorNum < colorDistribution[0]) {
            return palette[0];
        }
        else if (colorNum < (colorDistribution[0] + colorDistribution[1])) {
            return palette[1];
        }
        else if (colorNum < (colorDistribution[0] + colorDistribution[1] + colorDistribution[2])) {
            return palette[2];
        }
        else {
            return palette[3];
        }
    };

    
    function draw(p5) {    
        p5.background(bgColor);

        for (let i in shapesList) {
            let shape = shapesList[i];

            if (shape.targetx.toFixed(2) === shape.x.toFixed(2)) {
                shape.redirect();
            };

            shape.update();
            shape.display();
        };
        

        if (p5.frameCount % drawingTime === 0) {
            if (drawnShapes === totalShapes) {
                p5.noLoop();
            };

            let img = coloredShape(p5, rand.random_choice(["05", "06", "08", "10", "12", "15", "18"]));
            //img.filter(p5.BLUR, 0.5);

            const scaleValue = rand.random_int(200, 400);
            img.resize(scaleValue, scaleValue);

            const x = p5.windowWidth/2 + ( centered ? rand.random_num(-p5.windowWidth/5, p5.windowWidth/5) : rand.random_num(-p5.windowWidth/3, p5.windowWidth/3) ) - img.width/2;
            const y = rand.random_num(0, 350);

            drawnShapes++;

            const shape = new Shape(p5, rand, img, x, y);
            
            let shapesListTemp = shapesList;
            shapesListTemp.push(shape);
            setShapesList(shapesListTemp);
        };

    };

    return (
        <div>
            <Box>
                <Desktop>
                    <Sketch setup={setup} draw={draw} className="App" />
                </Desktop>
                
                <Tablet>
                    <Sketch setup={setup} draw={draw} className="App" />
                </Tablet>

                <Mobile>
                    <Sketch setup={setup} draw={draw} className="App" />
                </Mobile>

            </Box>
        </div>
    );
};

